import { GrUser, GrVmMaintenance } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { TbGauge, TbReportAnalytics } from 'react-icons/tb';
import { GiRing } from 'react-icons/gi';
import { SiMaterialdesignicons } from 'react-icons/si';
import { GiUnbalanced } from 'react-icons/gi';
import { GiTeePipe } from 'react-icons/gi';
import { TbReport } from 'react-icons/tb';
import { GrServices } from 'react-icons/gr';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { AiFillLayout } from 'react-icons/ai';
import { RiAspectRatioFill } from 'react-icons/ri';
import { BsTools } from 'react-icons/bs';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { GrPlan } from "react-icons/gr";

const tiles = [
  {
    sNo: 1,
    link: '/common/FixturesPlanTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <AiFillLayout size="5rem" color="#1864ab" />,
    p: 'Fixture Validation',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/appearanceplanTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <RiAspectRatioFill size="5rem" color="#495057" />,
    p: 'Appearanc MSA',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/instrumentTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#343a40" />,
    p: 'Instruments Calibration',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/instrumentcalibrationTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#343a40" />,
    p: 'Instruments Calibration',
    span: 'Plan (New)',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/attributeGaugesTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <TbGauge size="5rem" color="#495057" />,
    p: 'Attribute Gauges',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/OldFvpPlanTiles',
    isLink: true,
    clauseNo: 4,
    icon: <BsTools size="5rem" color="#495057" />,
    p: 'Old FVP',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/processAuditTiles',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#495057" />,
    p: 'PROCESS AUDIT',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/productAuditPlanTiles',
    isLink: true,
    clauseNo: 4,
    icon: <FaRegCalendarAlt size="5rem" color="#495057" />,
    p: 'PRODUCT AUDIT',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/PartLayoutPlanTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <AiFillLayout size="5rem" color="#1864ab" />,
    p: 'Part Layout',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 9,
    link: '/common/instrumentMsaPlanTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <RiAspectRatioFill size="5rem" color="#495057" />,
    p: 'Instruments MSA',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 9,
    link: '/common/gaugeMsaPlanTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <RiAspectRatioFill size="5rem" color="#495057" />,
    p: 'Gauges MSA',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 23,
    link: `/common/scopeCalibrationAgencyUpload`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'Scope Calibration Agency',
    span: 'UPLOAD',
    isIcon: true,
  },
  {
    sNo: 24,
    link: `/common/actionPlanMonitoringList`,
    isLink: true,
    icon: <FaRegCalendarAlt size="5rem" color="#003566" />,
    p: 'Action Plan',
    span: 'Monitoring',
    isIcon: true,
  },
  {
    sNo: 24,
    link: `/common/analysisEffectivenessSheet`,
    isLink: true,
    icon: <FaRegCalendarAlt size="5rem" color="#003566" />,
    p: 'Analysis Cum Effectiveness',
    span: 'Monitoring Sheet',
    isIcon: true,
  },
];

const RIDeparment = () => {
  return (
    <>
      <ChildHeader text="CALIBRATION TEAM" />
      <RenderTiles height="100%" tilesObj={tiles} />
    </>
  );
};

export default RIDeparment;
