export const moduleConstant = {
    api: '/api/actionPlanMonitoringRoute',
    heading: 'ACTION PLAN MONITORING SHEET',
    addButton: 'NEW ACTION PLAN',
  };
  
  export const tableHeaderObj = [
    [
      {
        text: 'S.No.',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '80px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Date of Audit',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '120px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Audit Name ( Process / Product )',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '120px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Audit Observation',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '200px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Action Taken',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '200px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Action Responsibility Person Name & Department',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '200px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Target Date with implementation ( First Time )',
        rowspan: 1,
        colspan: 2,
        style: {
          minWidth: '120px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Target Date with implemntation ( Second Time )',
        rowspan: 1,
        colspan: 2,
        style: {
          minWidth: '120px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Target Date with implemntation ( Third Time )',
        rowspan: 1,
        colspan: 2,
        style: {
          minWidth: '120px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Remarks',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '150px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'ACTION',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
    ],
    [
      {
        text: 'Date',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Status',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Date',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Status',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },{
        text: 'Date',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Status',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
    ],
  ];