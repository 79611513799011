import React from 'react';
import { Box } from '@mui/material';
import moment from 'moment';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import { TableInput } from '../../../../components/common';
import { RenderTable, TableCompHead } from '../../../../components/ui';
import { Table } from '../../../../components/ui/renderTable/styled';
import useConfirm from '../../../../components/common/useConfirm';
import styled from 'styled-components';

const monthOrder = [
  'april', 'may', 'june', 'july', 'august', 'september',
  'october', 'november', 'december', 'january', 'february', 'march'
];

const StyledCell = styled.td<{ isActive?: boolean; isClosing?: boolean }>`
  padding: 0;
  margin: 0;
  background-color: ${props => 
    props.isActive 
      ? props.isClosing 
        ? '#2b8a3e' 
        : '#40c057'
      : '#f8f9fa'
  };
  cursor: ${props => props.isActive === undefined ? 'default' : 'pointer'};
  color: ${props => props.isActive ? '#ffffff' : '#000000'};
  text-align: center;
  height: 45px;
  min-width: 80px;
  transition: transform 0.2s;
  
`;

const HeaderCell = styled.th`
  background-color: #4F81BD;
  color: white;
  padding: 10px;
  text-align: center;
  min-width: 150px;
`;

const SubHeaderCell = styled.th`
  background-color: #00B050;
  color: white;
  padding: 10px;
  text-align: center;
  min-width: 170px;
`;

interface StatusTableProps {
  sheetData: any;
  setSheetData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
}

const StatusTable = ({ sheetData, setSheetData, isLocked }: StatusTableProps) => {
  const [DialogConfirm, confirmAction] = useConfirm(
    'Confirm Action',
    'Are you sure you want to perform this action?'
  );

  const getYear = () => {
    return sheetData?.date ? new Date(sheetData.date).getFullYear() : '';
  };

  const canActivateClose = (monthName: string) => {
    const monthIndex = monthOrder.indexOf(monthName);
    // Find if any previous month is opened
    return monthOrder
      .slice(0, monthIndex)
      .some(month => sheetData.months[month].openingStatus);
  };

  const handleOpenClick = async (monthName: string) => {
    if (isLocked) return;

    const ans = await confirmAction();
    if (!ans) return;

    // Count current open months
    const openMonthCount = Object.values(sheetData.months)
      .filter((month: any) => month.openingStatus).length;

    // If this month is already open, allow toggling off
    if (sheetData.months[monthName].openingStatus) {
      setSheetData((prev: any) => ({
        ...prev,
        months: {
          ...prev.months,
          [monthName]: {
            ...prev.months[monthName],
            openingStatus: false,
            openDate: null
          }
        }
      }));
    } 
    // If no other month is open, allow opening this month
    else if (openMonthCount === 0) {
      setSheetData((prev: any) => ({
        ...prev,
        months: {
          ...prev.months,
          [monthName]: {
            ...prev.months[monthName],
            openingStatus: true,
            openDate: new Date()
          }
        }
      }));
    }
  };

  const handleCloseClick = async (monthName: string) => {
    if (isLocked) return;

    // Check if closing is allowed for this month
    if (!canActivateClose(monthName)) return;

    const ans = await confirmAction();
    if (!ans) return;

    setSheetData((prev: any) => ({
      ...prev,
      months: {
        ...prev.months,
        [monthName]: {
          ...prev.months[monthName],
          closingStatus: !prev.months[monthName].closingStatus,
          closeDate: !prev.months[monthName].closingStatus ? new Date() : null
        }
      }
    }));
  };

  const handleDateChange = (date: any, monthName: string, type: 'open' | 'close') => {
    if (isLocked) return;
    
    const dateField = type === 'open' ? 'openDate' : 'closeDate';
    setSheetData((prev: any) => ({
      ...prev,
      months: {
        ...prev.months,
        [monthName]: {
          ...prev.months[monthName],
          [dateField]: date ? moment(date).toDate() : null
        }
      }
    }));
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    monthName?: string
  ) => {
    const { name, value } = e.target;

    if (monthName) {
      // Update month-specific field
      setSheetData((prev: any) => ({
        ...prev,
        months: {
          ...prev.months,
          [monthName]: {
            ...prev.months[monthName],
            [name]: value
          }
        }
      }));
    } else {
      // Update sheet-level field (including remarks)
      setSheetData((prev: any) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  return (
    <Box sx={{ margin: 2, overflowX: 'auto' }}>
      <Table>
        <thead>
          <TableCompHead
            colSpan={14}
            rowSpan={1}
            labels={[]}
            values={[]}
            heading={`Product Observation Status for FY : ${getYear()}`}
          />
          <tr>
            <HeaderCell>Status</HeaderCell>
            {monthOrder.map(month => (
              <SubHeaderCell key={month}>
                {month.charAt(0).toUpperCase() + month.slice(1)}
              </SubHeaderCell>
            ))}
            <HeaderCell style={{minWidth: '300px'}}>Remarks</HeaderCell>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Open</td>
            {monthOrder.map(month => (
              <StyledCell
                key={`open-${month}`}
                isActive={sheetData.months?.[month]?.openingStatus}
                onClick={() => handleOpenClick(month)}
              >
                {sheetData.months?.[month]?.openingStatus ? 'O' : ''}
              </StyledCell>
            ))}
            <td>
              <TableInput
                type="text"
                name="openRemarks"
                value={sheetData.openRemarks || ''}
                onChange={handleInputChange}
                isView={false}
              />
            </td>
          </tr>
          <tr>
            <td>Close</td>
            {monthOrder.map(month => (
              <StyledCell
                key={`close-${month}`}
                isActive={sheetData.months?.[month]?.closingStatus}
                isClosing
                onClick={() => handleCloseClick(month)}
              >
                {sheetData.months?.[month]?.closingStatus ? 'C' : ''}
              </StyledCell>
            ))}
            <td>
              <TableInput
                type="text"
                name="closeRemarks"
                value={sheetData.closeRemarks || ''}
                onChange={handleInputChange}
                isView={false}
              />
            </td>
          </tr>
          <tr>
            <td>Opening Date</td>
            {monthOrder.map(month => (
              <td key={`open-date-${month}`}>
                <DatePickerMui
                  disabled={!sheetData.months?.[month]?.openingStatus || isLocked}
                  value={sheetData.months?.[month]?.openDate ? moment(sheetData.months[month].openDate) : null}
                  onChange={(date) => handleDateChange(date, month, 'open')}
                />
              </td>
            ))}
            <td>
              <TableInput
                type="text"
                name="openDateRemarks"
                value={sheetData.openDateRemarks || ''}
                onChange={handleInputChange}
                isView={false}
              />
            </td>
          </tr>
          <tr>
            <td>Closing Date</td>
            {monthOrder.map(month => (
              <td key={`close-date-${month}`}>
                <DatePickerMui
                  disabled={!sheetData.months?.[month]?.closingStatus || isLocked}
                  value={sheetData.months?.[month]?.closeDate ? moment(sheetData.months[month].closeDate) : null}
                  onChange={(date) => handleDateChange(date, month, 'close')}
                />
              </td>
            ))}
            <td>
              <TableInput
                type="text"
                name="closeDateRemarks"
                value={sheetData.closeDateRemarks || ''}
                onChange={handleInputChange}
                isView={false}
              />
            </td>
          </tr>
          <tr>
            <td>Total Raise</td>
            {monthOrder.map(month => (
              <td key={`total-raise-${month}`}>
                <TableInput
                  type="text"
                  name="totalRaise"
                  value={sheetData.months?.[month]?.totalRaise || ''}
                  onChange={(e) => handleInputChange(e, month)}
                  isView={false}
                />
              </td>
            ))}
            <td>
              <TableInput
                type="text"
                name="totalRaiseRemarks"
                value={sheetData.totalRaiseRemarks || ''}
                onChange={handleInputChange}
                isView={false}
              />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2}>Prepared By:</td>
            <td colSpan={5}>
              <TableInput
                type="text"
                name="preparedBy"
                value={sheetData?.preparedBy || ''}
                onChange={handleInputChange}
                isView={false}
              />
            </td>
            <td colSpan={2}>Approved By:</td>
            <td colSpan={5}>
              <TableInput
                type="text"
                name="approvedBy"
                value={sheetData?.approvedBy || ''}
                onChange={handleInputChange}
                isView={false}
              />
            </td>
            <td colSpan={3}></td>
          </tr>
        </tfoot>
      </Table>
      <DialogConfirm isSubmitContent={false} />
    </Box>
  );
};


export default StatusTable;