import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';
import { processObservationApiConstants } from '../../constant';

interface SheetModalProps {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSheetId: React.Dispatch<React.SetStateAction<string>>;
}

const SheetModal = ({ id, setIsModal, setSheetId }: SheetModalProps) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Sheet' : 'Create Sheet'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${processObservationApiConstants.getById}/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setModuleData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  }

  const handleSubmit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;

    const payload = {
      ...moduleData,
      date: moduleData.date ? moment(moduleData.date).toDate() : null
    };

    if (id) {
      await axios
        .put(`/api/${processObservationApiConstants.update}/${id}`, payload)
        .then(() => {
          setIsModal(false);
          setSheetId('');
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(`/api/${processObservationApiConstants.create}`, payload)
        .then(() => {
          setIsModal(false);
          setSheetId('');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'title'}
        name={'title'}
        html_for={'title'}
        label_name={'Title'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.title || ''}
        onChange={handleData}
      />
      <GridDatePicker
        label_name="Date"
        html_for={'date'}
        views={['year']}
        value={moduleData?.date ? moment(moduleData.date) : null}
        onChange={(date: any) => {
          setModuleData((prev:any) => ({
            ...prev,
            date: date
          }));
          setRender((prev) => !prev);
        }}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSubmit}
        />
      </div>
      <DialogSubmit isSubmitContent={false} />
    </>
  );
};

export default SheetModal;














////////////////////////////////
////////////////////////////
////////////////////////////////