import { AiOutlinePartition } from 'react-icons/ai';
import { FaList } from 'react-icons/fa';
import { GrUser } from 'react-icons/gr';
import { MdPlayLesson } from 'react-icons/md';

const tiles = [
  {
    sNo: 2,
    link: '/common/masterListOfProject?sort=createdAt',
    isLink: true,
    icon: <FaList size="5rem" color="#003566" />,
    p: 'MASTER LIST OF',
    span: 'PROJECT',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/npdPartList?sort=part_number',
    isLink: true,
    icon: <FaList size="5rem" color="#003566" />,
    p: 'MASTER LIST OF',
    span: 'NPD PART',
    isIcon: true,
  },
  {
    sNo: 3,
    link: `/common/devlopmentTracker`,
    isLink: true,
    icon: <GrUser size="5rem" color="#003566" />,
    p: 'Devlopment',
    span: 'Tracker',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/npdMatrixList',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'NPD',
    span: 'MATRIX',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/npdTimePlanList',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'NPD',
    span: 'TIME PLAN',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/lessonLearn/list',
    isLink: true,
    clauseNo: 1,
    icon: <MdPlayLesson size="5rem" color="#a12222" />,
    p: 'LESSON',
    span: 'LEARN',
    isIcon: true,
  },
];

export default tiles;
