export const listConstant = {
  childHeader: 'Process Audit Plan list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Process Audit Plan list',
  theadHeader: 'Process Audit Plan for the Machines for the Year',
  extracolSpan: 2,
};

export const checkListList = {
  childHeader: 'PROCESS STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'processAuditPlanRoute',
  gettableDataurl: 'processAuditPlanRoute/monthly',
  getPlanByid: 'processAuditPlanRoute',
  createPlanByid: 'processAuditPlanRoute',
  updatePlanByid: 'processAuditPlanRoute',
  sync: 'processAuditPlanRoute/handleSync',
  getAllCheckList: 'processAuditPlanRoute/getAllCheckList',
  checkListStatus: 'processAuditPlanRoute/handleStatus',
  checklistUpload: 'processAuditPlanRoute/handleUpload',
  checklistDeleteImage: 'processAuditPlanRoute/handleDeleteImage',
  checklistData: 'processAuditPlanRoute/checklistRoute',
  observationListData: 'processAuditPlanRoute/observationListRoute',
};

export const tilesConstant = {
  childHeaderText: 'Process Audit PLAN',
};

export const navigationConstant = {
  createPlanSchedule: '/common/processAuditPlanCreate',
  createChecklist: '/common/processAuditPlanChecklistCreate',
  createObservation: '/common/processAuditPlanObservationCreate',
  processObservationSheet: '/common/processObservationStatus'
};

export const processObservationConstants = {
  listChildHeader: 'Process Observation Status Sheet',
  addButtonLabel: 'New Sheet',
  datePickerLabel: 'Search By Year',
  modalTitle: 'CREATE',
  sheetChildHeader: 'Process Observation Status Sheet'
};

export const processObservationApiConstants = {
  base: 'processObservationStatus',
  getByYear: 'processObservationStatus/year',
  getById: 'processObservationStatus',
  create: 'processObservationStatus',
  update: 'processObservationStatus',
  delete: 'processObservationStatus'
};

