import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';
import GridSelect from '../../../../components/common/inputs/GridSelect';
import { Checkbox, MenuItem } from '@mui/material';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({});
  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Document' : 'Create Document'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterListOfProjectRoute/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterListOfProjectRoute/${id}`, data)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterListOfProjectRoute', data)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'name'}
        name={'name'}
        html_for={'name'}
        label_name={'Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.name || ''}
        onChange={handleChange}
      />
      <GridDatePicker
        label_name="Start Date"
        html_for={'start_date'}
        value={data?.start_date || null}
        onChange={(date) => {
          data.start_date = date;
          setRender((prev) => !prev);
        }}
      />
      <GridSelect
        name="category"
        label_name="Category:"
        html_for={'category'}
        value={data.category || ''}
        onChange={(e) => {
          data.category = e.target.value;
          setRender((prev) => !prev);
        }}
      >
        <MenuItem value="Proto">Proto</MenuItem>
        <MenuItem value="PPAP">PPAP</MenuItem>
      </GridSelect>
      <GridDatePicker
        label_name="SOP"
        html_for={'sop_date'}
        views={['year', 'month']}
        format={'MM-YYYY'}
        value={data?.sop_date || null}
        onChange={(date) => {
          data.sop_date = date;
          setRender((prev) => !prev);
        }}
      />
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report || false}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
