import React, { useState, useCallback } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { 
  Button, 
  CircularProgress, 
  Snackbar, 
  Alert,
  IconButton,
  Tooltip,
  Box
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';

interface WebpageDownloaderProps {
  contentRef: React.RefObject<HTMLElement>;
  fileName?: string;
  title?: string;
  buttonText?: string;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  showTimestamp?: boolean;
  /**
   * Scale factor for the PDF output (1 = actual size, 2 = double size, etc.)
   */
  scaleFactor?: number;
  /**
   * Maximum width for the PDF in mm
   */
  maxWidthMm?: number;
}

const WebpageDownloader: React.FC<WebpageDownloaderProps> = ({
  contentRef,
  fileName = 'downloaded-page',
  title,
  buttonText = 'Download PDF',
  buttonVariant = 'contained',
  showTimestamp = true,
  scaleFactor = 1,
  maxWidthMm = 1000 // Set a reasonable maximum width
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => setError(null);

  const captureFullContent = useCallback(async () => {
    if (!contentRef.current) {
      throw new Error('No content reference provided');
    }

    const element = contentRef.current;
    const originalStyles = new Map();

    // Store original styles and prepare elements
    const prepareElement = (el: HTMLElement) => {
      originalStyles.set(el, {
        width: el.style.width,
        height: el.style.height,
        overflow: el.style.overflow,
        maxHeight: el.style.maxHeight
      });

      el.style.width = 'auto';
      el.style.height = 'auto';
      el.style.overflow = 'visible';
      el.style.maxHeight = 'none';
    };

    // Prepare main element
    prepareElement(element);

    // Prepare all scrollable containers
    element.querySelectorAll('*').forEach((el) => {
      const computedStyle = window.getComputedStyle(el);
      if (computedStyle.overflow === 'auto' || computedStyle.overflow === 'scroll' ||
          computedStyle.overflowX === 'auto' || computedStyle.overflowX === 'scroll' ||
          computedStyle.overflowY === 'auto' || computedStyle.overflowY === 'scroll') {
        prepareElement(el as HTMLElement);
      }
    });

    try {
      // Wait for any dynamic content to settle
      await new Promise(resolve => setTimeout(resolve, 500));

      // Calculate actual content dimensions
      const contentWidth = element.scrollWidth;
      const contentHeight = element.scrollHeight;

      // Capture the content
      const canvas = await html2canvas(element, {
        width: contentWidth,
        height: contentHeight,
        scale: 1.5,
        useCORS: true,
        logging: false,
        windowWidth: contentWidth,
        windowHeight: contentHeight,
        imageTimeout: 0,
        backgroundColor: '#ffffff',
        onclone: (_, clonedElement) => {
          clonedElement.style.width = `${contentWidth}px`;
          clonedElement.style.height = `${contentHeight}px`;
        }
      });

      return canvas;
    } finally {
      // Restore original styles
      originalStyles.forEach((styles, el) => {
        Object.assign(el.style, styles);
      });
    }
  }, [contentRef]);

  const downloadAsPDF = async () => {
    setIsDownloading(true);
    setError(null);

    try {
      const canvas = await captureFullContent();

      // Convert canvas dimensions to mm (assuming 96 DPI)
      const pixelsPerMm = 96 / 25.4;
      const contentWidthMm = canvas.width / pixelsPerMm;
      const contentHeightMm = canvas.height / pixelsPerMm;

      // Calculate PDF dimensions while maintaining aspect ratio
      let pdfWidth = Math.min(contentWidthMm * scaleFactor, maxWidthMm);
      let pdfHeight = (pdfWidth * contentHeightMm) / contentWidthMm;
      const padding = 15; // 20mm padding on all sides


      // Create PDF with calculated dimensions
      const pdf = new jsPDF({
        orientation: pdfWidth > pdfHeight ? 'l' : 'p',
        unit: 'mm',
        format: [pdfWidth + (padding * 2), pdfHeight + (padding * 2)] // Add padding to both dimensions
      });

      // Add title if provided
      let yOffset = 10;
      if (title) {
        // Create black background for header
        pdf.setFillColor(0, 0, 0);
        const headerHeight = 20; // Increased header height
        pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), headerHeight, 'F');
        
        // Add white text with increased size
        pdf.setTextColor(255, 255, 255);
        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(15);
        pdf.text(title, pdf.internal.pageSize.getWidth() / 2, headerHeight/2 + 2, { 
          align: 'center',
          baseline: 'middle'
        });
        
        // Reset text color for rest of document
        pdf.setTextColor(0, 0, 0);
        yOffset = headerHeight + 10; // Adjusted offset for larger header
      }

      // Add timestamp if enabled
      if (showTimestamp) {
        pdf.setFont('helvetica', 'normal');
        pdf.setFontSize(10);
        pdf.text(
          `Generated: ${new Date().toLocaleString()}`,
          10,
          yOffset
        );
        yOffset += 10;
      }

      // Add the captured content
      const imgData = canvas.toDataURL('image/png');
      pdf.addImage(imgData, 'PNG', 10, yOffset, pdfWidth, pdfHeight);

      pdf.save(`${fileName}.pdf`);
    } catch (err) {
      console.error('Error generating PDF:', err);
      setError('Failed to generate PDF. Please try again.');
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Box>
      <Tooltip title="Download as PDF">
        <Button
          variant={buttonVariant}
          onClick={downloadAsPDF}
          disabled={isDownloading}
          startIcon={isDownloading ? <CircularProgress size={20} /> : <DownloadIcon />}
        >
          {isDownloading ? 'Generating...' : buttonText}
        </Button>
      </Tooltip>

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity="error"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default WebpageDownloader;
/////////////////////////
///////////////////////////
/////////////////////////////
////////////////////////////
///////////////////////////
/////////////////////////////
///////////////////////////////