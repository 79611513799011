import { useState } from 'react';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: { [key: string]: any };
  isView: boolean;
  title?: string;
  phase: any;
  employee: any;
}
const alignText = { textAlign: 'left' } as const;
const combinedStyles = { ...alignText };
const TBody2 = ({ employee, phase, moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  const monthLength = moduleData?.phase_1[0]?.months?.length || 0;
  return (
    <tbody>
      <tr>
        <td colSpan={2} style={combinedStyles}>
          Project Leader Review(PL)
        </td>
        <td colSpan={3} style={combinedStyles}>
          {isView ? (
            moduleData[`${phase}_employees_id`][0]?.employee_name
          ) : (
            <AutocompleteMuiCustom
              id="Employee"
              label="Employee"
              option_name="employee_name"
              arrayofObj={employee}
              value={
                moduleData[`${phase}_employees`][0] ||
                moduleData[`${phase}_employees_id`][0] ||
                ''
              }
              onChange={(e, value) => {
                moduleData[`${phase}_employees_id`][0] = value?._id || null;
                moduleData[`${phase}_employees`][0] = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td colSpan={monthLength + 4}></td>
      </tr>
      <tr>
        <td colSpan={2} style={combinedStyles}>
          Reviewed By CFT
        </td>
        <td colSpan={3} style={combinedStyles}>
          {isView ? (
            moduleData[`${phase}_employees_id`][1]?.employee_name
          ) : (
            <AutocompleteMuiCustom
              id="Employee"
              label="Employee"
              option_name="employee_name"
              arrayofObj={employee}
              value={
                moduleData[`${phase}_employees`][1] ||
                moduleData[`${phase}_employees_id`][1] ||
                ''
              }
              onChange={(e, value) => {
                moduleData[`${phase}_employees_id`][1] = value?._id || null;
                moduleData[`${phase}_employees`][1] = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td colSpan={monthLength + 4}></td>
      </tr>
      <tr>
        <td colSpan={2} style={combinedStyles}>
          Top Management Review (™)
        </td>
        <td colSpan={3} style={combinedStyles}>
          {isView ? (
            moduleData[`${phase}_employees_id`][2]?.employee_name
          ) : (
            <AutocompleteMuiCustom
              id="Employee"
              label="Employee"
              option_name="employee_name"
              arrayofObj={employee}
              value={
                moduleData[`${phase}_employees`][2] ||
                moduleData[`${phase}_employees_id`][2] ||
                ''
              }
              onChange={(e, value) => {
                moduleData[`${phase}_employees_id`][2] = value?._id || null;
                moduleData[`${phase}_employees`][2] = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td colSpan={monthLength + 4}></td>
      </tr>
      <tr style={{ height: '45px' }}>
        <td colSpan={2} style={combinedStyles}>
          Management Review and Feedback:
        </td>
        <td colSpan={3}></td>
        <td colSpan={monthLength + 4}></td>
      </tr>
    </tbody>
  );
};

export default TBody2;
