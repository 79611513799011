import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';

const CustomerMileStone = ({ isView, item, project }: any) => {
  const [render, setRender] = useState(false);
  return (
    <table
      style={{
        width: '100%',
        borderCollapse: 'collapse',
        border: '1px solid black',
      }}
    >
      <tbody>
        {/* First Row */}
        <tr>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            Customer:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="customer"
              type="text"
              value={item.customer}
              onChange={(e) => {
                item.customer = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            SBS1:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="sbs1"
              type="text"
              value={item.sbs1}
              onChange={(e) => {
                item.sbs1 = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>

        {/* Second Row */}
        <tr>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            Project Leader:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="project_leader"
              type="text"
              value={item.project_leader}
              onChange={(e) => {
                item.project_leader = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            SBS2:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="sbs2"
              type="text"
              value={item.sbs2}
              onChange={(e) => {
                item.sbs2 = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>

        {/* Third Row */}
        <tr>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            Project:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            {project}
          </td>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            TBT-VFA:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="tbt_vfa"
              type="text"
              value={item.tbt_vfa}
              onChange={(e) => {
                item.tbt_vfa = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>

        {/* Fourth Row */}
        <tr>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            Product Family:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="product_family"
              type="text"
              value={item.product_family}
              onChange={(e) => {
                item.product_family = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            EM Note 3:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="em_note_3"
              type="text"
              value={item.em_note_3}
              onChange={(e) => {
                item.em_note_3 = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>

        {/* Fifth Row */}
        <tr>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            Project Close Date:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="project_close_date"
              type="text"
              value={item.project_close_date}
              onChange={(e) => {
                item.project_close_date = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            2 DP / Production Trial:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="production_trial"
              type="text"
              value={item.production_trial}
              onChange={(e) => {
                item.production_trial = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>

        {/* Sixth Row */}
        <tr>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            Project Start Date:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="project_start_date"
              type="text"
              value={item.project_start_date}
              onChange={(e) => {
                item.project_start_date = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            SOP:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="sop"
              type="text"
              value={item.sop}
              onChange={(e) => {
                item.sop = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>

        {/* Seventh Row */}
        <tr>
          <td
            style={{
              padding: '10px',
              fontWeight: 'bold',
              border: '1px solid black',
            }}
          >
            Display:
          </td>
          <td style={{ padding: '10px', border: '1px solid black' }}>
            <TableInput
              isView={isView}
              name="display"
              type="text"
              value={item.display}
              onChange={(e) => {
                item.display = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CustomerMileStone;
