import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import moment from 'moment';
import AlertMui from '../../../../components/common/AlertMui';

import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../components/common/FormControlMui';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import useConfirm from '../../../../components/common/useConfirm';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { Checkbox } from '@mui/material';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import AutocompleteGridmui from '../../../../components/common/autocomplete/AutocompleteGridmui';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';
interface Iprop {
  setIsNewPartModal: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setPartId: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface IPart {
  _id?: string;
  part_number: string | null;
  part_name: string | null;
  customer_id?: any;
  project_id?: any;
  date_orig: moment.Moment;
  createdAt?: Date;
  tech_query_target_date?: Date;
  rfq_date?: Date;
  tech_query_comp_date?: Date;
  remarks?: string;
  isSubmitted: string;
  isPublish?: boolean;
  volume?: number;
  checkPoints?: any;
  exclude_report?: boolean;
}
const checkPoints: any = [
  {
    checkpoint: 'Feasibility Go ahead',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Approved Cad Available',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Approved Drawing Available',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Drawing request',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Child parts Approved drawing',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'List of child parts & Target Date',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Part code for Out source Process',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'List of tooling & Target Date',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'List of Fixtures & Target Date',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'List Of gauges & Target Date',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Leakage Testing',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Packaging STD',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'List Packaging Material',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Trolly for parts',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Rack and Bin requirement',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Cad aling Accoding to drawing',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'NPS Sheet',
    status: '',
    remark: '',
  },
];

const AddEditPart = ({ setIsNewPartModal, id, setPartId }: Iprop) => {
  const [partData, setPartData] = useState<IPart>({
    part_number: null,
    part_name: null,
    date_orig: moment(),
    isSubmitted: 'PENDING',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [render, setRender] = useState(false);
  const [alert, setAlert] = useState(false);
  const [projects, setProjects] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'DRAFT PART',
    `Are you sure you want to ${id ? 'update' : 'save'} this part?`,
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPartData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    axios
      .get('/api/customerRoutes')
      .then((res) => {
        setCustomers(res.data.results);
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/masterListOfProjectRoute/projectList/autoComplete')
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/npdPartRoute/${id}`)
        .then((res) => {
          setPartData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setPartData((prev) => prev);
    }
  }, [id]);

  const onSubmit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (!partData.project_id) return window.alert('Project field is required');
    setIsLoading(true);
    if (id) {
      await axios
        .put(`/api/npdPartRoute/${id}`, partData)
        .then((res) => {
          setIsLoading(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      partData.checkPoints = checkPoints;
      await axios
        .post('/api/npdPartRoute', partData)
        .then((res) => {
          setIsLoading(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'part_number'}
        name={'part_number'}
        html_for={'part_number'}
        label_name={'Part Number:'}
        input_type={'text'}
        focusType={'onblur'}
        value={partData?.part_number || ''}
        onChange={handlePartData}
      />
      <AutocompleteGridmui
        id="customer_name"
        label="Customer"
        option_name="customer_name"
        arrayofObj={customers}
        value={partData.customer_id || ''}
        onChange={(e, value) => {
          partData.customer_id = value;
          setRender((prev) => !prev);
        }}
      />
      <AutocompleteGridmui
        id="name"
        label="Project:"
        option_name="name"
        arrayofObj={projects}
        value={partData.project_id || ''}
        onChange={(e, value) => {
          partData.project_id = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'volume'}
        name={'volume'}
        html_for={'volume'}
        label_name={'Volume:'}
        input_type={'number'}
        focusType={'onblur'}
        value={partData?.volume || ''}
        onChange={handlePartData}
      />

      <GridDatePicker
        label_name="DATE (Original):"
        html_for={'date_orig'}
        onChange={(date) => {
          partData.date_orig = date;
          setRender((prev) => !prev);
        }}
        value={moment(partData.date_orig || null)}
      />
      <GridDatePicker
        label_name="RFQ Receive Date:"
        html_for={'rfq_date'}
        onChange={(date) => {
          partData.rfq_date = date;
          setRender((prev) => !prev);
        }}
        value={moment(partData.rfq_date || null)}
      />
      <GridDatePicker
        label_name="Tech Query Target Date:"
        html_for={'tech_query_target_date'}
        onChange={(date) => {
          partData.tech_query_target_date = date;
          setRender((prev) => !prev);
        }}
        value={moment(partData.tech_query_target_date || null)}
      />
      <GridDatePicker
        label_name="Tech Query Complete Date:"
        html_for={'tech_query_comp_date'}
        onChange={(date) => {
          partData.tech_query_comp_date = date;
          setRender((prev) => !prev);
        }}
        value={moment(partData.tech_query_comp_date || null)}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'string'}
        focusType={'onblur'}
        value={partData?.remarks || ''}
        onChange={handlePartData}
      />
      <div>
        <Checkbox
          name="exclude_report"
          checked={partData?.exclude_report || false}
          onChange={(e) => {
            setPartData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={onSubmit}
        />
      </div>

      <DialogSubmit />
      <AlertMui
        alert={alert}
        message={
          'Please select a customer from the list. The customer field cannot be left empty'
        }
        setAlert={setAlert}
        severity={'warning'}
      />
    </>
  );
};

export default AddEditPart;
