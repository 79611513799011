export const parseComplexDate = (value: any): string | null => {
    if (!value) return null;
    
    // Convert to string and clean up
    const dateStr = value.toString().trim();
    
    // Comprehensive month mapping including various formats
    const monthMap: { [key: string]: number } = {
      // Full month names (case-insensitive)
      'january': 1, 'february': 2, 'march': 3, 'april': 4,
      'may': 5, 'june': 6, 'july': 7, 'august': 8,
      'september': 9, 'october': 10, 'november': 11, 'december': 12,
      
      // Three letter abbreviations
      'jan': 1, 'feb': 2, 'mar': 3, 'apr': 4,
      // 'may': 5, // Already included above
      'jun': 6, 'jul': 7, 'aug': 8,
      'sep': 9, 'oct': 10, 'nov': 11, 'dec': 12
    };
  
    // Function to parse month string to number
    const parseMonth = (monthStr: string): number => {
      // Try direct number conversion first
      const numMonth = parseInt(monthStr);
      if (!isNaN(numMonth) && numMonth >= 1 && numMonth <= 12) {
        return numMonth;
      }
  
      // Clean up month string
      const cleanMonthStr = monthStr.toLowerCase().replace(/[^a-z]/g, '');
      
      // Try to match with our month map
      return monthMap[cleanMonthStr] || monthMap[cleanMonthStr.substring(0, 3)] || 1;
    };
  
    // Handle Excel serial number dates
    if (typeof value === 'number' && !isNaN(value)) {
      const excelDate = new Date((value - 25569) * 86400 * 1000);
      if (!isNaN(excelDate.getTime())) {
        return excelDate.toISOString();
      }
    }
  
    // Handle month-year only format
    const monthYearRegex = /^([A-Za-z0-9]+)[-./\s]*([0-9]{4})$/i;
    const monthYearMatch = dateStr.match(monthYearRegex);
    if (monthYearMatch) {
      const monthPart = monthYearMatch[1];
      const year = parseInt(monthYearMatch[2]);
      const month = parseMonth(monthPart);
      
      return new Date(year, month - 1, 1).toISOString();
    }
  
    // Handle various full date formats
    const parts = dateStr.split(/[-./\s]+/).filter(Boolean);
    if (parts.length >= 2) {
      const day = parts.length >= 3 ? parseInt(parts[0]) : 1;
      const month = parseMonth(parts[parts.length >= 3 ? 1 : 0]);
      const year = parseInt(parts[parts.length >= 3 ? 2 : 1]);
  
      // Handle 2-digit years
      const adjustedYear = year < 100 ? (year < 50 ? 2000 + year : 1900 + year) : year;
  
      // Create and validate date
      const date = new Date(adjustedYear, month - 1, day);
      if (!isNaN(date.getTime())) {
        return date.toISOString();
      }
    }
  
    // Try parsing with built-in Date parser as last resort
    const fallbackDate = new Date(dateStr);
    if (!isNaN(fallbackDate.getTime())) {
      return fallbackDate.toISOString();
    }
  
    return null;
  };

  // Helper function to simplify text for comparison
const simplifyText = (text: string): string => {
    return text?.toString().toLowerCase().replace(/[^a-z0-9]/g, '') || '';
  };
  
  // Helper function to validate and transform enum values
  const validateEnum = (value: any, allowedValues: string[]): string | null => {
    const simplifiedValue = simplifyText(value);
    const matchedValue = allowedValues.find(allowed => 
      simplifyText(allowed) === simplifiedValue
    );
    return matchedValue || '';
  };


// Enum values from the Lesson Learn model
const CATEGORY_ENUM = [
    'Customer',
    'Warranty',
    'Inhouse',
    'Development (TGW/TGR)',
    'Supplier',
    'Kaizen',
    'Continual Improvement',
    'Competitors',
    'Sister Plant',
    'Field failure'
  ];
  
  const ACTION_STATUS_ENUM = ['Open', 'Closed'];
  
  const QDCFTSRM_ENUM = [
    'Quality',
    'Delivery',
    'Cost',
    'Feature',
    'Technology',
    'Sustainability',
    'Risk',
    'Management'
  ];
  
  const YES_NO_ENUM = ['Y', 'N'];


  export const lessonLearnedUploadConfig = [
    {
      header: 'IAI PLANT',
      dataField: 'plant',
      rowsToBeConsidered: 1
    },
    {
      header: 'DATE',
      dataField: 'reciept_date',
      rowsToBeConsidered: 1,
      transform: (value: any) => {
        if (!value) return null;
        return parseComplexDate(value);
      }
    },
    {
      header: 'Category- Customer/Warranty /Inhouse/ Development (TGW/TGR) / Supplier/ Kaizen, Continual Improvement / Competitors / Sister Plant / Field failure',
      dataField: 'category',
      rowsToBeConsidered: 1,
      transform: (value: any) => validateEnum(value, CATEGORY_ENUM)
    },
    {
      header: 'Customer',
      dataField: 'customer_name',
      rowsToBeConsidered: 1  // These headers span 3 rows in the excel
    },
    {
      header: 'Family',
      dataField: 'family',
      rowsToBeConsidered: 1
    },
    {
      header: 'Part Name',
      dataField: 'part_name',
      rowsToBeConsidered: 1
    },
    {
      header: 'Part No .',
      dataField: 'part_number',
      rowsToBeConsidered: 1
    },
    {
      header: 'Defect Details',
      dataField: 'defect_details',
      rowsToBeConsidered: 1
    },
    {
      header: 'Repeated/New',
      dataField: 'repeated',
      rowsToBeConsidered: 1
    },
    {
      header: 'Qty.',
      dataField: 'qty',
      rowsToBeConsidered: 1,
      transform: (value: any) => Number(value) || null
    },
    {
      header: 'Root Cause for Occurrence',
      dataField: 'root_cause_for_occ',
      rowsToBeConsidered: 1
    },
    {
      header: 'Root Cause for Non Detection',
      dataField: 'root_cause_for_non_detection',
      rowsToBeConsidered: 1
    },
    {
      header: 'Corrective Action of Occurrence',
      dataField: 'corrective_action_for_occ',
      rowsToBeConsidered: 1
    },
    {
      header: 'Corrective Action of Non Detection',
      dataField: 'corrective_action_for_non_detection',
      rowsToBeConsidered: 1
    },
    {
      header: 'Action Implementation Status (open/closed)',
      dataField: 'action_implementation_status',
      rowsToBeConsidered: 1,
      transform: (value: any) => validateEnum(value, ACTION_STATUS_ENUM)
    },
    {
      header: 'Lessons Learnt',
      dataField: 'lesson_learnt',
      rowsToBeConsidered: 1
    },
    {
      header: 'Q- Quality D- Delivery C- Cost F- Feature T- Technology S- Sustainability R-Risk M- Management',
      dataField: 'qdcftsrm',
      rowsToBeConsidered: 1,
      transform: (value: any) => validateEnum(value, QDCFTSRM_ENUM)
    },
    // AM5-AM6 Applicability and Action columns
    {
      header: '1_AM5-AM6_Applicability (Y/N)',  // Using underscore to join multi-row headers
      dataField: 'applicability_and_action_am5_am6_applicabiliy',
      rowsToBeConsidered: 3,
      transform: (value: any) => validateEnum(value, YES_NO_ENUM)
    },
    {
      header: '1_AM5-AM6_Action Implemented (Y / N) ',
      dataField: 'applicability_and_action_am5_am6_action',
      rowsToBeConsidered: 3,
      transform: (value: any) => validateEnum(value, YES_NO_ENUM)
    },
    // 1.0 85 KW TSI IND Applicability and Action columns
    {
      header: '2_1.0 85 KW TSI IND_Applicability (Y/N)',
      dataField: 'applicability_and_action_1085_kw_tsi_ind_applicabiliy',
      rowsToBeConsidered: 3,
      transform: (value: any) => validateEnum(value, YES_NO_ENUM)
    },
    {
      header: '2_1.0 85 KW TSI IND_Action Implemented (Y / N)',
      dataField: 'applicability_and_action_1085_kw_tsi_ind_action',
      rowsToBeConsidered: 3,
      transform: (value: any) => validateEnum(value, YES_NO_ENUM)
    },
    // AM370 Applicability and Action columns
    {
      header: '3_AM370_Applicability (Y/N)',
      dataField: 'applicability_and_action_am_370_applicabiliy',
      rowsToBeConsidered: 3,
      transform: (value: any) => validateEnum(value, YES_NO_ENUM)
    },
    {
      header: '3_AM370_Action Implemented (Y / N)',
      dataField: 'applicability_and_action_am_370_action',
      rowsToBeConsidered: 3,
      transform: (value: any) => validateEnum(value, YES_NO_ENUM)
    }
  ];

