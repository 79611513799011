import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import TBodyRight from './TBodyRight';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { Grid, Stack } from '@mui/material';

interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
  setRowIdx: any;
  setIsOpenPopup: any;
}
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  backgroundColor: '#ffec99',
  // color: '#fff',
} as const;
const TBody = ({
  moduleData,
  isView,
  allmonth,
  setRowIdx,
  setIsOpenPopup,
}: IProps) => {
  const [render, setRender] = useState(false);
  const handelDeleteRows = async (index: number) => {
    const ans = window.confirm('Are you sure you want to delete this row');
    if (!ans) return;
    moduleData?.list.splice(index, 1);
    setRender((prev) => !prev);
  };
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <td
              rowSpan={2}
              style={{
                ...stickStylesColumn,
                left: 0,
                width: '50px',
                minWidth: '50px',
              }}
            >
              {index + 1}
            </td>
            <td
              rowSpan={2}
              style={{
                ...stickStylesColumn,
                left: 50,
                width: '250px',
                minWidth: '250px',
              }}
            >
              {isView ? (
                item?.row_id && item?.row_id?.part_number
              ) : (
                <Grid
                  sx={{ border: '2px solid #d3f9d8' }}
                  container
                  xs={12}
                  direction="row"
                  alignItems={'center'}
                >
                  <Grid item xs={10}>
                    <p
                      style={{
                        fontSize: '1.2rem',
                      }}
                    >
                      {typeof item?.row_id === 'object' && item?.row_id !== null
                        ? item?.row_id?.part_number
                        : item?.a1 || ''}
                      {!item?.row_id && 'Pick Part Number'}
                    </p>
                  </Grid>

                  {item.row_id ? (
                    <Grid item xs={2}>
                      <RemoveIconButton
                        tooltipTitle="remove part"
                        onClick={() => {
                          item['row_id'] = null;
                          item[`a1`] = null;
                          item[`a2`] = null;
                          setRender((prev: any) => !prev);
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={2}>
                      <AddIconButton
                        tooltipTitle="add part"
                        onClick={() => {
                          setIsOpenPopup(true);
                          setRowIdx(index);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </td>
            <td
              rowSpan={2}
              style={{
                ...stickStylesColumn,
                left: 300,
                width: '150px',
                minWidth: '150px',
              }}
            >
              {item?.a2}
            </td>
            {/* always constant ⬇️*/}
            <TBodyRight
              item={item}
              isView={isView}
              index={index}
              allmonth={allmonth}
            />
            <td rowSpan={2} style={{ minWidth: '240px' }}>
              <Stack direction={'row'}>
                <TableInput
                  isView={isView}
                  style={{ height: '80px', width: '200px' }}
                  name="remarks"
                  type="text"
                  value={item.remarks}
                  onChange={(e) => {
                    item.remarks = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
                {!isView && (
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                )}
              </Stack>
            </td>
          </tr>
          <tr>
            {allmonth.map((month: any, mIdx: number) => (
              <td
                width="80px"
                height="45px"
                style={{
                  backgroundColor:
                    (item[month].status == 'noProduction' && '#6741d9') ||
                    (item[month].doneDate && '#37b24d'),
                  color: 'white',
                  textTransform: 'capitalize',
                  fontSize: '2.5rem',
                }}
              >
                {item[month].isTask && 'A'}
                {item[month].status == 'noProduction' && 'NS'}
              </td>
            ))}
          </tr>
          {/* ---x---- */}
        </>
      ))}
    </tbody>
  );
};

export default TBody;
