import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import GridDatePicker from '../../components/common/inputs/GridDatePicker';
import GridSelect from '../../components/common/inputs/GridSelect';
import GridInputs from '../../components/common/inputs/GridInputs';
import { StyledNotesField } from '../../components/common/FormControlMui';
import DescriptionTextfield from '../../components/common/DescriptionNotes';
import { moduleConstant } from './helpers/constant';
import { Loader } from '../../components/common/Loader';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    date_of_audit: new Date(),
    audit_name: '',
    audit_observation: '',
    why1: '',
    why2: '',
    why3: '',
    why4: '',
    why5: '',
    corrective_action: '',
    preventive_action: '',
    category: '',
    standardization: '',
    effectiveness_check_first: null,
    effectiveness_check_second: null,
    effectiveness_check_third: null,
    effectiveness_check_first_status: '',
    effectiveness_check_second_status: '',
    effectiveness_check_third_status: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Action Plan' : 'Create Action Plan'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`${moduleConstant.api}/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleAnalysisEffectivenessData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSubmit = async () => {
    if (!data.audit_name) {
      alert('Please select audit name');
      return;
    }
    if(!data.date_of_audit){
      alert('Please select date of audit');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);

    if (id) {
      await axios
        .put(`${moduleConstant.api}/${id}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      await axios
        .post(`${moduleConstant.api}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <GridDatePicker
        label_name="Date of Audit"
        html_for={'date_of_audit'}
        value={data?.date_of_audit || null}
        onChange={(date) => {
          setData((prev: any) => ({ ...prev, date_of_audit: date }));
        }}
      />
      <GridSelect
        name="audit_name"
        label_name="Audit Name"
        html_for={'audit_name'}
        value={data?.audit_name || ''}
        onChange={handleAnalysisEffectivenessData}
      >
        <MenuItem value="process">Process</MenuItem>
        <MenuItem value="product">Product</MenuItem>
      </GridSelect>

      <StyledNotesField>
        <DescriptionTextfield
          name="audit_observation"
          onChange={handleAnalysisEffectivenessData}
          value={data?.audit_observation || ''}
          label={'Audit Observation :'}
        />
      </StyledNotesField>
      

      <GridInputs
        id={'why1'}
        name={'why1'}
        html_for={'why1'}
        label_name={'Why - 1'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.why1 || ''}
        onChange={handleAnalysisEffectivenessData}
      />

      <GridInputs
        id={'why2'}
        name={'why2'}
        html_for={'why2'}
        label_name={'Why - 2'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.why2 || ''}
        onChange={handleAnalysisEffectivenessData}
      />

      <GridInputs
        id={'why3'}
        name={'why3'}
        html_for={'why3'}
        label_name={'Why - 3'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.why3 || ''}
        onChange={handleAnalysisEffectivenessData}
      />

      <GridInputs
        id={'why4'}
        name={'why4'}
        html_for={'why4'}
        label_name={'Why - 4'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.why4 || ''}
        onChange={handleAnalysisEffectivenessData}
      />

      <GridInputs
        id={'why5'}
        name={'why5'}
        html_for={'why5'}
        label_name={'Why - 5'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.why5 || ''}
        onChange={handleAnalysisEffectivenessData}
      />


      <StyledNotesField>
        <DescriptionTextfield
          name="corrective_action"
          onChange={handleAnalysisEffectivenessData}
          value={data?.corrective_action || ''}
          label={'Corrective Action :'}
        />
      </StyledNotesField>

      <StyledNotesField>
        <DescriptionTextfield
          name="preventive_action"
          onChange={handleAnalysisEffectivenessData}
          value={data?.preventive_action || ''}
          label={'Preventive Action :'}
        />
      </StyledNotesField>

      <GridSelect
        name="category"
        label_name="Category"
        html_for={'category'}
        value={data?.category || ''}
        onChange={handleAnalysisEffectivenessData}
      >
        <MenuItem value="minor">Minor</MenuItem>
        <MenuItem value="major">Major</MenuItem>
        <MenuItem value="ofi">OFI</MenuItem>
      </GridSelect>

      <StyledNotesField>
        <DescriptionTextfield
          name="standardization"
          onChange={handleAnalysisEffectivenessData}
          value={data?.standardization || ''}
          label={'Standardization :'}
        />
      </StyledNotesField>

      
      <GridDatePicker
        label_name="Effectiveness Check (1st) Date"
        html_for={'effectiveness_check_first'}
        value={data?.effectiveness_check_first || null}
        onChange={(date) => {
          setData((prev: any) => ({ ...prev, effectiveness_check_first: date }));
        }}
      />

      <GridInputs
        id={'effectiveness_check_first_status'}
        name={'effectiveness_check_first_status'}
        html_for={'effectiveness_check_first_status'}
        label_name={'Status (1st)'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.effectiveness_check_first_status || ''}
        onChange={handleAnalysisEffectivenessData}
      />


      <GridDatePicker
        label_name="Effectiveness Check (2nd) Date"
        html_for={'effectiveness_check_second'}
        value={data?.effectiveness_check_second || null}
        onChange={(date) => {
          setData((prev: any) => ({ ...prev, effectiveness_check_second: date }));
        }}
      />

      <GridInputs
        id={'effectiveness_check_second_status'}
        name={'effectiveness_check_second_status'}
        html_for={'effectiveness_check_second_status'}
        label_name={'Status (2nd)'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.effectiveness_check_second_status || ''}
        onChange={handleAnalysisEffectivenessData}
      />

      <GridDatePicker
        label_name="Effectiveness Check (3rd) Date"
        html_for={'effectiveness_check_third'}
        value={data?.effectiveness_check_third || null}
        onChange={(date) => {
          setData((prev: any) => ({ ...prev, effectiveness_check_third: date }));
        }}
      />

      <GridInputs
        id={'effectiveness_check_third_status'}
        name={'effectiveness_check_third_status'}
        html_for={'effectiveness_check_third_status'}
        label_name={'Status (3rd)'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.effectiveness_check_third_status || ''}
        onChange={handleAnalysisEffectivenessData}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSubmit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;