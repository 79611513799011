import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
} from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import formatDate from '../../components/common/formatDate';
import TableScroll from '../../components/ui/renderTable/TableScroll';
import TextAreaInput from '../../components/common/TextAreaInput';
import { moduleConstant, tableHeaderObj } from './helpers/constant';

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [actionPlanId, setActionPlanId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const page = useQuery('page') || 1;
  const date = useQuery('date');

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    setData([]);
    setTotalPages(0);
    try {
      if (!date) return;
      const res = await axios.get(
        `${moduleConstant.api}/monthly/${date}?page=${page}&perPage=${perPage || 10}`
      );
      
      if (res?.data?.results) {
        setData(res.data.results);
        setPerPage(res.data.perPage);
        setTotalPages(res.data.pages);
      }
    } catch (err) {
      console.log(err);
      setData([]);
      setTotalPages(0);
    }
  };

  const deleteActionPlan = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    
    try {
      await axios.delete(`${moduleConstant.api}/${id}`);
      alert('Action plan deleted successfully');
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  useDebounce(fetchData, 500, [page, date, isModal]);

  return (
    <>
      <ChildHeader text={moduleConstant.heading}>
        <AddButton
          onClick={() => setIsModal(true)}
          label={moduleConstant.addButton}
        />
      </ChildHeader>
      <SearchFilterContainer filter={false} style={{ display: 'flex' }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </SearchFilterContainer>
      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data.map((item: any, index: number) => (
              <TableRow key={item._id}>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {perPage * (+page - 1) + index + 1}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.date_of_audit && formatDate(item?.date_of_audit)}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.audit_name}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.audit_observation}
                </TableCell>


                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.why1}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.why2}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.why3}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.why4}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.why5}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.corrective_action}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.preventive_action}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.category}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.standardization}
                </TableCell>


                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.effectiveness_check_first && formatDate(item?.effectiveness_check_first)}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.effectiveness_check_first_status}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.effectiveness_check_second && formatDate(item?.effectiveness_check_second)}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.effectiveness_check_second_status}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.effectiveness_check_third && formatDate(item?.effectiveness_check_third)}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid black' }}>
                  {item?.effectiveness_check_third_status}
                </TableCell>
                <TableCell sx={{ border: '1px solid black' }}>
                  <Stack direction="row" sx={{ justifyContent: 'center' }}>
                    <EditIconButton
                      tooltipTitle="EDIT"
                      onClick={() => {
                        setActionPlanId(item?._id || '');
                        setIsModal(true);
                      }}
                    />
                    <DeleteIconButton
                      tooltipTitle="DELETE"
                      onClick={() => {
                        deleteActionPlan(item?._id || '');
                      }}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setActionPlanId(null);
          setIsModal(false);
        }}
        title={moduleConstant.heading}
      >
        <Create id={actionPlanId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete />
    </>
  );
};

export default List;