import { RiAspectRatioFill, RiSlideshowFill, RiTeamFill } from 'react-icons/ri';
import { BsJournalBookmarkFill } from 'react-icons/bs';
import { HiDocumentReport } from 'react-icons/hi';
import { BiStore } from 'react-icons/bi';
import { SlNotebook } from 'react-icons/sl';
import { TbBuildingWarehouse, TbEyeTable } from 'react-icons/tb';
import { GrVmMaintenance } from 'react-icons/gr';
import { AiFillLayout } from 'react-icons/ai';
import { TbGauge } from 'react-icons/tb';
import { BsTools } from 'react-icons/bs';
import { FaFireExtinguisher, FaLayerGroup } from 'react-icons/fa';

import AdminHomeRenderTiles from './AdminHomeRenderTiles';
import { MdOutlineLiveHelp, MdWarehouse } from 'react-icons/md';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { GiFirstAidKit } from 'react-icons/gi';
import { SiKaios } from 'react-icons/si';

// don't change id sequence in adminTilesObj

const adminTilesObj = [
    // start VDA
    {
        id: 1,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 'P2',
        p: 'PROJECT',
        span: 'MANAGEMENT',
        isIcon: false,
        red: 'red',
    },
    {
        id: 111,
        link: '/common/listOfSlideShow',
        isLink: true,
        icon: <RiSlideshowFill size="5rem" color="#a12222" />,
        p: 'LIST OF',
        span: 'SLIDESHOW',
        isIcon: true,
    },
    {
        id: 2,
        link: '/common/riDepartment',
        isLink: true,
        icon: <HiDocumentReport size="5rem" color="#a12222" />,
        p: 'RI',
        span: 'DEPARTMENT',
        isIcon: true,
    },
    {
        id: 60,
        isNotValidate: true,
        link: '/common/documentPendingForApproval',
        isLink: true,
        icon: <HiDocumentReport size="5rem" color="#a12222" />,
        p: 'DOCUMENTS PENDING',
        span: 'FOR APPROVAL',
        isIcon: true,
    },
    {
        id: 3,
        link: '/common/pdiDepartment',
        isLink: true,
        icon: <TbEyeTable size="5rem" color="#a12222" />,
        p: 'PDI',
        span: 'DEPARTMENT',
        isIcon: true,
    },
    {
        id: 4,
        link: '/common/qualityDepartmentHome',
        isLink: true,
        icon: <SlNotebook size="5rem" color="#a12222" />,
        p: 'QUALITY',
        span: 'DEPARTMENT',
        isIcon: true,
    },
    {
        id: 5,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 'P3',
        p: 'PLANNING PRODUCT ',
        span: 'AND PROCESS MANAGEMENT',
        isIcon: false,
        red: 'red',
    },
    {
        id: 6,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 'P4',
        p: 'IMPLEMENTATION OF PRODUCT',
        span: 'AND PROCESS DEVELOPMENT',
        isIcon: false,
        red: 'red',
    },
    {
        id: 7,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 'P5',
        p: 'SUPPLIER',
        span: 'MANAGEMENT',
        isIcon: false,
        red: 'red',
    },
    {
        id: 8,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 'P6',
        p: 'PRODUCT PROCESS',
        span: 'ANALYSIS',
        isIcon: false,
        red: 'red',
    },
    {
        id: 9,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 'P7',
        p: 'CUSTOMER CARE /',
        span: 'CUSTOMER SATISFACTION / SERVICE',
        isIcon: false,
        red: 'red',
    },
    //
    // start clause
    {
        id: 10,
        link: '/common/home/prductionDept',
        isLink: true,
        icon: <MdWarehouse size="5rem" color="#343a40" />,
        p: 'Production',
        span: 'Department',
        isIcon: true,
    },

    {
        id: 11,
        link: '/common/clause1',
        isLink: true,
        clauseNo: 1,
        p: 'PRODUCTION PREPARATION /',
        span: 'NEW PRODUCT DEVELOPEMENT',
        isIcon: false,
    },
    {
        id: 12,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 2,
        p: 'REGULATION FOR INITIAL',
        span: 'PRODUCTION DEVELOPEMENT',
        isIcon: false,
    },
    {
        id: 13,
        link: '/admin/clause3',
        isLink: true,
        clauseNo: 3,
        p: 'CHANGING',
        span: 'MANAGEMENT',
        isIcon: false,
    },
    {
        id: 14,
        link: '/admin/clause4',
        isLink: true,
        clauseNo: 4,
        p: 'STANDARDS',
        span: 'MANAGEMENT',
        isIcon: false,
    },
    {
        id: 15,
        // link: 'https://iai.secure.aqua.mushinlabs.com',
        link: '/common/clause5',
        // target: '_blank',
        isLink: true,
        clauseNo: 5,
        p: 'EDUCATION AND',
        span: 'TRAINING',
        isIcon: false,
    },
    {
        id: 16,
        link: '/admin/clause6',
        isLink: true,
        clauseNo: 6,
        p: 'QUALITY AUDIT AND',
        span: 'PROCESS VERIFICATION',
        isIcon: false,
    },
    {
        id: 17,
        link: '/admin/clause7',
        isLink: true,
        clauseNo: 7,
        p: 'Supplier',
        span: 'Control',
        isIcon: false,
    },
    {
        id: 18,
        link: '/admin/clause8',
        isLink: true,
        clauseNo: 8,
        p: 'HANDLING ABNORMILITY',
        span: 'IN QUALITY',
        isIcon: false,
    },
    {
        id: 19,
        link: '/common/management5S',
        isLink: true,
        clauseNo: 9,
        p: '5S',
        span: 'MANAGEMENT',
        isIcon: false,
    },
    {
        id: 20,
        link: '/admin/clause10',
        isLink: true,
        clauseNo: 10,
        p: 'EQUIPMENT / INSPECTION',
        span: "EQUIPMENT'S MANAGEMENT",
        isIcon: false,
    },
    {
        id: 21,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 11,
        p: 'IMPLEMENTATION OF',
        span: 'STANDARDS',
        isIcon: false,
    },
    {
        id: 22,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 12,
        p: 'PRODUCTS',
        span: 'MANAGEMENT',
        isIcon: false,
    },
    {
        id: 23,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 13,
        p: 'HANDLING',
        span: 'MANAGEMENT',
        isIcon: false,
    },
    {
        id: 24,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 14,
        p: 'CRITICAL PARTS',
        span: 'MANAGEMENT',
        isIcon: false,
    },
    {
        id: 25,
        link: 'https://iai.secure.aqua.mushinlabs.com',
        target: '_blank',
        isLink: true,
        clauseNo: 15,
        p: 'ADEQUATE TESTING',
        span: 'FACILITY',
        isIcon: false,
    },
    {
        id: 26,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 16,
        p: 'PROCESS',
        span: 'AUDIT',
        isIcon: false,
    },
    {
        id: 27,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 17,
        p: 'SCALE AND TOP',
        span: 'MANAGEMENT BANDWIDTH',
        isIcon: false,
    },
    {
        id: 28,
        link: '/common/clause18',
        isLink: true,
        clauseNo: 18,
        p: 'SAFETY',
        span: '',
        isIcon: false,
    },
    {
        id: 29,
        link: '/admin/modules',
        isLink: true,
        clauseNo: 19,
        p: 'LEGAL COMPLIANCE',
        span: 'AND ENVIRONMENT',
        isIcon: false,
    },
    // {
    //   id: 26,
    //   link: '/admin/production',
    //   isLink: true,
    //   icon: <HiDocumentReport size="5rem" color="#a12222" />,
    //   p: 'PRODUCTION',
    //   span: 'MANAGEMENT',
    //   isIcon: true,
    // },
    {
        id: 30,
        link: '/common/storeManagement',
        isLink: true,
        icon: <BiStore size="5rem" color="#1864ab" />,
        p: 'STORE',
        span: 'MANAGEMENT',
        isIcon: true,
    },
    {
        id: 31,
        link: '/common/pfmeaList',
        isLink: true,
        icon: <AiFillLayout size="5rem" color="#1864ab" />,
        p: 'PFMEA',
        span: 'MANAGEMENT',
        isIcon: true,
    },
    {
        id: 36,
        link: '/common/layeredAuditPlanTiles',
        isLink: true,
        clauseNo: 4,
        icon: <FaLayerGroup size="5rem" color="#495057" />,
        p: 'Layered Audit',
        span: 'Plan',
        isIcon: true,
    },
    {
        id: 37,
        link: '/common/kraList',
        isLink: true,
        clauseNo: 4,
        icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
        p: 'KRA',
        span: 'DASHBOARD',
        isIcon: true,
    },
    {
        id: 39,
        link: `/common/calibrationTeam`,
        isLink: true,
        icon: <RiTeamFill size="5rem" color="#003566" />,
        p: 'CALIBRATION',
        span: 'TEAM',
        isIcon: true,
    },
    {
        id: 40,
        link: `/common/kaizenSubmissionList`,
        isLink: true,
        icon: <SiKaios size="5rem" color="#003566" />,
        p: 'KAIZEN',
        span: 'SUBMISSION',
        isIcon: true,
    },
    {
        id: 38,
        link: `/common/mushinHelpDesk`,
        isLink: true,
        icon: <MdOutlineLiveHelp size="5rem" color="#003566" />,
        p: 'MUSHIN HELP',
        span: 'DESK',
        isIcon: true,
    },
];

const validateUniqueIds = (tiles: any) => {
    const seenIds = new Set();
    tiles.forEach((tile: any) => {
        if (seenIds.has(tile.id)) {
            throw new Error(
                `Duplicate ID detected for "${tile.p} ${tile.span}". Each ID must be unique. Please update the ID for this tile without modifying existing IDs.`,
            );
        }
        seenIds.add(tile.id);
    });
};
const AdminHome = () => {
    // Validate IDs during component initialization
    try {
        validateUniqueIds(adminTilesObj);
    } catch (error) {
        console.error(error);
        // This will crash the application
        throw error;
    }

    return <AdminHomeRenderTiles tilesObj={adminTilesObj} />;
};

export default AdminHome;
