import { StyledTableHeaderRow } from '../../../../../components/common/rowFreeze';
import TableCompHead from '../../../../../components/ui/TableCompHead';

interface IProps {
  moduleData: any;
  isView: boolean;
  assignData: any;
}

const theadData = [
  { name: 'SR. NO.', style: { minWidth: '60px' } },
  { name: 'LOCK', style: { minWidth: '60px' } },
  { name: 'BIN NO.', style: { minWidth: '120px' } },
  { name: 'PART NO.', style: { minWidth: '200px' } },
  { name: 'ERP No. / Drawing No.', style: { minWidth: '160px' } },
  { name: 'Fox Pro No. / Ref. Drg. No.', style: { minWidth: '160px' } },
  { name: 'QTY', style: { minWidth: '160px' } },
];
const theadData2 = [
  { name: 'SR. NO.', style: { minWidth: '60px' } },
  { name: 'BIN NO.', style: { minWidth: '120px' } },
  { name: 'PART NO.', style: { minWidth: '200px' } },
  { name: 'QTY', style: { minWidth: '160px' } },
  { name: 'ACTION', style: { minWidth: '200px' } },
];

const THead = ({ moduleData, isView, assignData }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={assignData ? 7 : 7}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="STORE SETUP"
      />
      {!assignData && (
        <StyledTableHeaderRow>
          {theadData.map((heading, index) => (
            <th key={heading.name + index} style={heading.style}>
              {heading.name}
            </th>
          ))}
        </StyledTableHeaderRow>
      )}
      {assignData && (
        <>
          <tr>
            <th style={{ background: '#51cf66' }}>Pick Me First</th>
            <th style={{ background: '#ffe066' }}>Pick Me Second</th>
            <th style={{ background: '#ffc078' }}>Pick Me Third</th>
            <th colSpan={2} style={{ background: '#fa5252' }}>
              Pick Me In The End
            </th>
          </tr>
          <StyledTableHeaderRow>
            {theadData2.map((heading, index) => (
              <th key={heading.name + index} style={heading.style}>
                {heading.name}
              </th>
            ))}
          </StyledTableHeaderRow>
        </>
      )}
    </thead>
  );
};

export default THead;
