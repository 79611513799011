import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PersitedDatePicker, TableCustom } from '../../../components/common';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ModalCustom from '../../../components/common/Modal';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import { productObservationConstants, productObservationApiConstants, navigationConstant } from '../constant';
import SheetModal from './components/SheetModal';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Title', rowspan: 1, colspan: 1 },
    { text: 'Year', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const List = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [sheetId, setSheetId] = useState('');
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const navigate = useNavigate();
  const date = useQuery('date');

  useEffect(() => {
    if (date) {
      axios
        .get(`/api/${productObservationApiConstants.getByYear}/${date}`)
        .then((res) => {
          if (res.data) setTableData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [render, date, isOpenPopup]);

  const handleDelete = async (id: string) => {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this sheet?'
    );
    
    if (isConfirmed) {
      try {
        await axios.delete(`/api/${productObservationApiConstants.delete}/${id}`);
        alert('Sheet deleted successfully');
        setRender((prev) => !prev);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getYear = (date: string) => {
    return new Date(date).getFullYear();
  };

  return (
    <Box>
      <ChildHeader text={productObservationConstants.listChildHeader}>
        <AddButton
          label={productObservationConstants.addButtonLabel}
          onClick={() => setIsOpenPopup(true)}
        />
      </ChildHeader>
      
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker
          label={productObservationConstants.datePickerLabel}
          views={['year']}
        />
      </Box>
      
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData?.map((item: any, index: number) => (
            <TableRow key={item._id}>
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell align="center">{item.title}</TableCell>
              <TableCell align="center">{getYear(item.date)}</TableCell>
              <TableCell>
                <Stack direction="row" sx={{ justifyContent: 'center' }}>
                  <ViewIconButton
                    tooltipTitle="View Sheet"
                    onClick={() => {
                      navigate(`${navigationConstant.productObservationSheet}/${item._id}`);
                    }}
                  />
                  <EditIconButton
                    tooltipTitle="Edit"
                    onClick={() => {
                      setSheetId(item._id);
                      setIsOpenPopup(true);
                    }}
                  />
                  <DeleteIconButton
                    tooltipTitle="Delete"
                    onClick={() => handleDelete(item._id)}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableCustom>

      <ModalCustom
        title={productObservationConstants.modalTitle}
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
          setSheetId('');
        }}
      >
        <SheetModal
          setIsModal={setIsOpenPopup}
          id={sheetId}
          setSheetId={setSheetId}
        />
      </ModalCustom>
    </Box>
  );
};

export default List;