export const moduleConstant = {
    api: '/api/analysisEffectivenessMonitoringRoute',
    heading: 'ANALYSIS CUM EFFECTIVENESS MONITORING SHEET',
    addButton: 'NEW ENTRY',
  };
  
  export const tableHeaderObj = [
    [
      {
        text: 'S.No.',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '80px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Date of Audit',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '120px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Audit Name ( Process / Product )',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '120px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Audit Observation',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '200px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: '5 - Why Analysis',
        rowspan: 1,
        colspan: 5,
        style: {
          minWidth: '200px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Corrective Action',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '200px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Preventive Action',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '200px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Category ( Minor / Major / Observation for Improvement (OFI) )',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '200px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Standardization',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '200px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Effectiveness Check - 1',
        rowspan: 1,
        colspan: 2,
        style: {
          minWidth: '120px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Effectiveness Check - 2',
        rowspan: 1,
        colspan: 2,
        style: {
          minWidth: '120px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Effectiveness Check - 3',
        rowspan: 1,
        colspan: 2,
        style: {
          minWidth: '120px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'ACTION',
        rowspan: 2,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
    ],
    [
      {
        text: 'Why - 1',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Why - 2',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Why - 3',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Why - 4',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Why - 5',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Date',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Status',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Date',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Status',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },{
        text: 'Date',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
      {
        text: 'Status',
        rowspan: 1,
        colspan: 1,
        style: {
          minWidth: '100px',
          border: '1px solid black',
          background: '#38d9a9',
        },
      },
    ],
  ];