import React, { useEffect, useState } from 'react';
import TableCustom from '../../../components/common/customTable/TableCustom';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import { SearchBar } from '../../../components/common/SearchBar';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import ChildHeader from '../../../components/ui/ChildHeader';
import axios from 'axios';
import useDebounce from '../../../hooks/useDebounce';
import { EditIconButton } from '../../../components/common/button/CustomIconButton';
import { useQuery } from '../../../hooks/UseQuery';
import { useNavigate } from 'react-router-dom';
import { CustomPagination } from '../../../components/common';
import FilterBy from '../../../components/common/FilterBy';
import formatDate from '../../../components/common/formatDate';
import moment from 'moment';
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'NAME', rowspan: 1, colspan: 1 },
    { text: 'START DATE', rowspan: 1, colspan: 1 },
    { text: 'CATEGORY', rowspan: 1, colspan: 1 },
    { text: 'SOP (MM/YY)', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];
const filterOptions = [{ value: 'createdAt', name: 'A-Z' }];

const SelectNpdPart = ({ link }: { link: string }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const sort = useQuery('sort');
  const page = useQuery('page') || 1;

  const fetchData = () => {
    axios
      .get(
        `/api/masterListOfProjectRoute?page=${page}&sortBy=${sort}&search=${search}&perPage=${10}`,
      )
      .then((res) => {
        if (res.data) {
          setData(res?.data?.results);
          setPerPage(res?.data?.perPage);
          setTotalPages(res?.data?.pages);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounce(fetchData, 500, [page, search, sort]);

  return (
    <div>
      <ChildHeader text="PROJECT LIST"></ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy
            sx={{ width: 200 }}
            name="sort"
            label="Sort"
            options={filterOptions}
          />
        </Stack>
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center" width={10}>
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.name}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.start_date || '')}
                  </TableCell>
                  <TableCell align="center">{item.category}</TableCell>
                  <TableCell align="center">
                    {item.sop_date && moment(item.sop_date).format('MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          navigate(
                            `${link}/${item?._id}?project=${item.name}&isView=false`,
                          );
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
    </div>
  );
};

export default SelectNpdPart;
