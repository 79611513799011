import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import axios from 'axios';

// Interface for defining how each column should be mapped and processed
interface ColumnMapping {
  header: string;                    // The header text to match in the Excel file
  dataField: string;                 // The field name in the output object
  rowsToBeConsidered: number;        // Number of rows to combine for header matching
  required?: boolean;                // Whether this field is required in the output
  transform?: (value: any) => any;   // Optional transformation function for the cell value
}

// Props interface for the ExcelUploader component
interface ExcelUploaderProps {
  config: ColumnMapping[];               // Configuration array for column mappings
  apiEndpoint: string;                   // API endpoint for uploading processed data
  minimumColumnHeaderMatch: number;      // Minimum number of headers that must match
  onSuccess?: (response: any) => void;   // Callback for successful upload
  skipForDataRows: number;              // Number of rows to skip after header before data starts
  footer: number;           // Number of rows to ignore from bottom
  onError?: (error: any) => void;       // Callback for error handling
}

const ExcelUploader: React.FC<ExcelUploaderProps> = ({
  config,
  apiEndpoint,
  minimumColumnHeaderMatch,
  onSuccess,
  onError,
  skipForDataRows,
  footer
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const CHUNK_SIZE = 500; // Size of data chunks for batch processing

  // Converts row and column indices to Excel cell address (e.g., 'A1', 'B2')
  const getCellAddress = (row: number, col: number): string => {
    return XLSX.utils.encode_cell({ r: row, c: col });
  };

  // Handles merged cells by unmerging them and filling each cell with the merged value
  const unmergeAndFillCells = (worksheet: XLSX.WorkSheet): XLSX.WorkSheet => {
    const newWorksheet = { ...worksheet };
    
    // Return original if no merged cells
    if (!worksheet['!merges']) return worksheet;

    // Process each merged cell range
    worksheet['!merges'].forEach(merge => {
      // Get value from top-left cell of merged range
      const topLeftCell = getCellAddress(merge.s.r, merge.s.c);
      const mergedValue = worksheet[topLeftCell]?.v || '';

      // Fill each cell in the merged range with the value
      for (let row = merge.s.r; row <= merge.e.r; row++) {
        for (let col = merge.s.c; col <= merge.e.c; col++) {
          const cellAddress = getCellAddress(row, col);
          newWorksheet[cellAddress] = {
            t: 's',                    // Type: string
            v: mergedValue,            // Raw value
            w: mergedValue.toString()  // Formatted value
          };
        }
      }
    });

    // Clear merge information
    newWorksheet['!merges'] = undefined;
    return newWorksheet;
  };

  // Creates a combined header string from multiple rows for matching
  const createCompoundHeader = (
    sheet: any[][],
    startRow: number,
    column: number,
    rowsToConsider: number
  ): string => {
    let headerParts: string[] = [];
    
    // Collect non-empty values from specified rows
    for (let i = 0; i < rowsToConsider; i++) {
      const rowIndex = startRow + i;
      if (rowIndex < sheet.length) {
        const value = sheet[rowIndex][column];
        if (value) {
          headerParts.push(value.toString().trim());
        }
      }
    }
    
    // Join collected parts with underscore
    return headerParts.join('_');
  };

  // Finds the header row and creates mappings between Excel columns and data fields
  const findHeaderRowAndMappings = (sheet: any[][]): {
    headerRowIndex: number;
    columnMappings: Map<number, string>;
  } => {
    const configHeaders = config.map(c => c.header);
    console.log('1')
    
    // Examine each row to find headers
    for (let rowIndex = 0; rowIndex < sheet.length; rowIndex++) {
      console.log('In loop', rowIndex, sheet[rowIndex]);
      const columnMappings = new Map<number, string>();
      let matchCount = 0;

      // Check each column against config
      sheet[rowIndex].forEach((_, colIndex) => {
        const matchingConfig = config.find(c => {
          const compoundHeader = createCompoundHeader(
            sheet,
            rowIndex,
            colIndex,
            c.rowsToBeConsidered
          );
          
          return simplifyText(c.header) === simplifyText(compoundHeader);
        });

        if (matchingConfig) {
          columnMappings.set(colIndex, matchingConfig.dataField);
          matchCount++;
        }
      });

      // Return if minimum match threshold is met
      if (matchCount >= minimumColumnHeaderMatch) {
        return { headerRowIndex: rowIndex, columnMappings };
      }
    }

    throw new Error('Could not find header row matching configuration');
  };

// Processes data rows into objects based on column mappings
const processDataRows = (
  sheet: any[][],
  headerRowIndex: number,
  columnMappings: Map<number, string>
): any[] => {
  try {
    // Validate input parameters
    if (!Array.isArray(sheet) || sheet.length === 0) {
      throw new Error('Invalid sheet data');
    }

    if (headerRowIndex < 0 || headerRowIndex >= sheet.length) {
      throw new Error('Invalid header row index');
    }

    // Get total rows and calculate data range considering footer
    const totalRows = sheet.length;
    const dataEndIndex = Math.max(0, totalRows - (footer || 0));
    const startIndex = Math.min(headerRowIndex + skipForDataRows, totalRows);
    
    // Safety check for valid range
    if (startIndex >= dataEndIndex) {
      return [];
    }

    // Get initial rows (excluding footer)
    const dataRows = sheet.slice(startIndex, dataEndIndex);

    // First, filter out placeholder and empty rows
    const filteredRows = dataRows.filter(row => {
      // Safety check for row
      if (!Array.isArray(row)) return false;

      // Check if row is completely empty
      const hasAnyValue = row.some(cell => 
        cell !== null && cell !== undefined && cell !== ''
      );
      if (!hasAnyValue) return false;

      // Get values for all mapped columns
      let firstValue: string | null = null;
      let allValuesMatch: boolean = true;
      let hasAtLeastOneValue: boolean = false;

      columnMappings.forEach((_, colIndex) => {
        // Safety check for column index
        if (colIndex >= row.length) return;

        const currentValue = row[colIndex];
        if (currentValue !== null && currentValue !== undefined && currentValue !== '') {
          const stringValue = String(currentValue).trim();
          hasAtLeastOneValue = true;

          if (firstValue === null) {
            firstValue = stringValue;
          } else if (stringValue !== firstValue) {
            allValuesMatch = false;
          }
        }
      });

      // If all mapped column values are same and we have at least one value,
      // it's a placeholder row
      return !(allValuesMatch && hasAtLeastOneValue);
    });

    // Convert filtered rows to objects using mappings
    return filteredRows
      .map(row => {
        const obj: Record<string, any> = {};

        columnMappings.forEach((dataField, colIndex) => {
          try {
            // Safety check for column index
            if (colIndex >= row.length) return;

            const value = row[colIndex];
            const mapping = config.find(c => c.dataField === dataField);

            if (mapping) {
              if (mapping.transform) {
                try {
                  obj[dataField] = mapping.transform(value);
                } catch (transformError) {
                  console.error(`Transform error for field ${dataField}:`, transformError);
                  obj[dataField] = null;
                }
              } else {
                obj[dataField] = value || null;
              }
            }
          } catch (columnError) {
            console.error(`Error processing column ${colIndex}:`, columnError);
          }
        });

        return obj;
      })
      .filter(row => Object.keys(row).length > 0);

  } catch (error) {
    console.error('Error in processDataRows:', error);
    return [];
  }
};

  // Simplifies text for consistent comparison
  const simplifyText = (text: string): string => {
    return text.toString()
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '');
  };

  // Handles the file upload process
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUploading(true);
      const file = event.target.files?.[0];

      if (!file) return;

      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

          console.log('HERE IS THE FIRST SHEET:', firstSheet);
          
          const unmergedSheet = unmergeAndFillCells(firstSheet);

          console.log('HERE IS THE SHEET AFTER UNMERGING:', unmergedSheet);
          
          const jsonData = XLSX.utils.sheet_to_json(unmergedSheet, { header: 1 }) as any[][];
          
          console.log('jsonData:', jsonData);

          const { headerRowIndex, columnMappings } = findHeaderRowAndMappings(jsonData);
          console.log('8')

          const processedData = processDataRows(jsonData, headerRowIndex, columnMappings);
          console.log('9')

          // Validate required fields
          processedData.forEach((row, index) => {
            config.forEach(mapping => {
              if (mapping.required && !row[mapping.dataField]) {
                throw new Error(`Required field "${mapping.header}" missing in row ${index + 1}`);
              }
            });
          });

          await sendChunkedData(processedData);
          onSuccess?.('uploaded');
          
        } catch (error) {
          onError?.(error);
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      onError?.(error);
    } finally {
      setIsUploading(false);
      event.target.value = ''; // Reset file input
    }
  };

  // Sends processed data to API in chunks to handle large datasets
  const sendChunkedData = async (processedData: any[]) => {
    try {
      const chunks = [];
      for (let i = 0; i < processedData.length; i += CHUNK_SIZE) {
        chunks.push(processedData.slice(i, i + CHUNK_SIZE));
      }

      let totalProcessed = 0;
      for (const [index, chunk] of chunks.entries()) {
        const response = await axios.post(apiEndpoint, { data: chunk });
        totalProcessed += chunk.length;
        console.log(`Processed chunk ${index + 1}/${chunks.length}, Total processed: ${totalProcessed}`);
        console.log('Response:', response.data);
      }

      onSuccess?.({ message: `Successfully processed ${totalProcessed} records` });
    } catch (error) {
      onError?.(error);
    }
  };

  return (
    <Button
      variant="contained"
      component="label"
      disabled={isUploading}
      style={{
        background: '#76102a',
        marginRight: '1rem',
      }}
    >
      {isUploading ? 'Uploading...' : 'Upload Excel'}
      <input
        type="file"
        hidden
        accept=".xlsx,.xls"
        onChange={handleFileUpload}
      />
    </Button>
  );
};

export default ExcelUploader;

/////////////////////////////
///////////////////////////////////
/////////////////////////////////