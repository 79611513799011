import { CopyAll } from '@mui/icons-material';
import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { BiPaste } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import lodash from 'lodash';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { Loader } from '../../../components/common/Loader';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import { tabData } from './helpers/constants';
import useGetData from './helpers/useGetData';
interface MyData {
  employee: any;
  moduleData: any;
  isLoading: boolean;
  setIsLoading: any;
  setUseEffectRender: any;
  error?: string | null;
  setModuleData: any;
}
const Create = () => {
  const { projectId } = useParams();
  const { clipData, setClipData } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [tabValue, setTabValue] = useState(0);
  const {
    employee,
    moduleData,
    isLoading,
    setIsLoading,
    setUseEffectRender,
    setModuleData,
    error,
  }: MyData = useGetData(projectId);

  const onSubmit = async () => {
    if (moduleData._id) {
      moduleData.project_id = projectId;
      axios
        .put(`/api/npdTimePlanRoute/${moduleData._id}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('updated successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      moduleData.project_id = projectId;
      axios
        .post(`/api/npdTimePlanRoute`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('created successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const uploadImageFile = async (e: any, phase: string, index: number) => {
    await onSubmit();
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    formData.append('file', files[0]);
    setIsLoading(true);
    axios
      .patch(
        `/api/npdTimePlanRoute/uploadFile/${phase}/${index}/${moduleData?._id}
      `,
        formData,
      )
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setUseEffectRender((prev: any) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  if (isLoading) return <Loader loader={isLoading} />;

  const addColumn = () => {
    const updatedData = { ...moduleData };
    ['phase_1', 'phase_2', 'phase_3', 'phase_4', 'phase_5'].forEach((phase) => {
      updatedData[phase].forEach((activity: any) => {
        activity.months.push({}, {}, {}, {});
      });
    });
    setModuleData(updatedData);
  };

  const deleteColumn = () => {
    const updatedData = { ...moduleData };
    ['phase_1', 'phase_2', 'phase_3', 'phase_4', 'phase_5'].forEach((phase) => {
      updatedData[phase].forEach((activity: any) => {
        if (activity.months.length >= 4) {
          activity.months.splice(-4, 4);
        } else {
          activity.months = [];
        }
      });
    });
    setModuleData(updatedData);
  };

  const handleCopy = () => {
    setClipData(moduleData);
  };

  const handlePaste = () => {
    if (clipData) {
      setModuleData((prev: any) => {
        const updatedData = { ...prev };

        // Iterate through each phase
        ['phase_1', 'phase_2', 'phase_3', 'phase_4', 'phase_5'].forEach(
          (phase: any, index) => {
            const clipPhase = clipData[phase]; // Get the clip data for the current phase
            const monthsKey = `${phase}_months`; // Key for the phase_x_months array

            updatedData[monthsKey] = clipData[monthsKey];

            updatedData[phase] = updatedData[phase].map(
              (activity: any, i: number) => {
                const clipActivity: any = clipPhase[i]; // Get the corresponding clip data activity

                activity.months = activity.months.map(
                  (month: any, monthIndex: number) => {
                    // If 'P' is true in the corresponding clipData month
                    if (clipActivity?.months[monthIndex]?.P) {
                      month.P = true;
                    }
                    return month; // Return the original month object
                  },
                );

                return activity; // Return the updated activity object
              },
            );
          },
        );

        return updatedData; // Return the updated moduleData object
      });

      setClipData(null);
    }
  };

  const addRow = () => {
    const months = Array.from({ length: 16 }, () => ({}));
    const updatedData = { ...moduleData };
    const phase = tabData[tabValue].phase;
    updatedData[phase].push({
      months: lodash.cloneDeep(months),
    });
    setModuleData(updatedData);
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="NPD TIME PLAN">
          {clipData ? (
            <CustomButton
              sx={{
                backgroundColor: '#1c7ed6',
                marginRight: '1rem',
              }}
              icon={<BiPaste />}
              onClick={handlePaste}
            >
              PASTE DATA
            </CustomButton>
          ) : (
            <CustomButton
              sx={{
                backgroundColor: '#1c7ed6',
                marginRight: '1rem',
              }}
              icon={<CopyAll />}
              onClick={handleCopy}
            >
              COPY DATA
            </CustomButton>
          )}
          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
              marginRight: '0.8rem',
            }}
            onClick={addRow}
          >
            ADD ROW
          </CustomButton>

          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
              marginRight: '0.8rem',
            }}
            onClick={addColumn}
          >
            ADD COLUMN
          </CustomButton>
          <CustomButton
            sx={{
              backgroundColor: '#e03131',
            }}
            onClick={deleteColumn}
            icon={<MdDelete />}
          >
            DELETE COLUMN
          </CustomButton>
          {!isView && (
            <SubmitButton label="Submit" onClick={() => onSubmit()} />
          )}
        </ChildHeader>
      </Box>
      <Paper elevation={2} style={{ margin: '1rem 0.5rem 0rem 0.5rem' }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          textColor="secondary"
        >
          <Tab label={'PHASE-1 (PLAN AND DEFINE PROGRAM)'} />
          <Tab label={'PHASE-2 (PRODUCT DESIGN AND DEVELOPMENT)'} />
          <Tab label={'PHASE-3 (PROCESS DESIGN STAGE)'} />
          <Tab label={'PHASE-4 (PRODUCT AND PROCESS VALIDATION)'} />
          <Tab label={'PHASE-5 (FEEDBACK, ASSESSMENT AND CORRECTIVE ACTION)'} />
        </Tabs>
      </Paper>
      <TController
        {...{
          moduleData,
          isView,
          employee,
          uploadImageFile,
          setModuleData,
        }}
        phase={tabData[tabValue].phase}
        title={tabData[tabValue].title}
      />
    </Box>
  );
};

export default Create;
