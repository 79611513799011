import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { createConstant } from '../../constant';

interface Ifoot {
  isView: any;
  moduleData: any;
}
const TFoot = ({ isView, moduleData }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th colSpan={createConstant.extracolSpan} rowSpan={4}>
          LEGENDS
        </th>
        <th rowSpan={1} colSpan={3}>
          PLAN
        </th>
        <th style={{ background: '#ffd43b' }}>P</th>
        <th rowSpan={4} colSpan={10} align="left">
          <h1>Guidelines for Part Layout Plan.</h1>
          1- Last Financial parts of customer compliants parts to be consider in
          plan.
          <br></br>
          2- Parts dispatch more than 1500 in last financial year will be
          considered in plan.
          <br></br>
          3- Plan could be revised during the year when part is obselete or not
          moving or any specific reason.
          <br></br>
          4- Parts dispatch less than 1500 in last financial year will be
          considered when required.
          <br></br>
          5- Part Layout frequency once in a year.
        </th>
      </tr>
      <tr>
        <th colSpan={3}>Actual/Done</th>
        <th style={{ background: '#2f9e44' }}>A</th>
      </tr>
      <tr>
        <th colSpan={3}>No Schedule</th>
        <th style={{ background: '#6741d9' }}>NS</th>
      </tr>
      <tr>
        <th colSpan={3}>Re Planned</th>
        <th style={{ background: '#00b0f0' }}>R</th>
      </tr>

      <tr>
        <th colSpan={(13 + createConstant.extracolSpan) / 2} align="left">
          Prepared By:
          <TableInput
            isView={isView}
            name="remarks"
            type="text"
            value={moduleData.report_prepared_by}
            onChange={(e) => {
              moduleData.report_prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={(13 + createConstant.extracolSpan) / 2} align="left">
          Approved By:
          <TableInput
            isView={isView}
            name="remarks"
            type="text"
            value={moduleData.report_approved_by}
            onChange={(e) => {
              moduleData.report_approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
