export const checklist1 = [
  {
    s_no: 1,
    heading: 'Receipt inspection of components',
    check_point: 'SUB ASSY.',
  },
  {
    s_no: 2,
    heading: 'Receipt inspection of components',
    check_point: ' O-Ring',
  },
  {
    s_no: 3,
    heading: 'Receipt inspection of components',
    check_point: ' Plastic Conn. Conn.',
  },
  {
    s_no: 4,
    heading: 'Receipt inspection of components',
    check_point: 'Hose VMQ_0902',
  },
  {
    s_no: 5,
    heading: 'Receipt inspection of components',
    check_point: 'Oetiker Clamp',
  },
  {
    s_no: 6,
    heading: 'Receipt inspection of components',
    check_point: 'Oetiker Clamp',
  },
  {
    s_no: 7,
    heading: 'Receipt inspection of components',
    check_point: 'Plastic CAP_M/c connector',
  },
  {
    s_no: 8,
    heading: 'Receipt inspection of components',
    check_point: 'Plastic Cap',
  },
  {
    s_no: 9,
    heading: 'Component storage & Issue',
    check_point: 'QTY',
  },
  {
    s_no: 10,
    heading: 'Component storage & Issue',
    check_point: 'All Type of Child Parts',
  },
  {
    s_no: 11,
    heading: 'Component storage & Issue',
    check_point: 'Store cover',
  },
  {
    s_no: 12,
    heading: 'Component storage & Issue',
    check_point: 'Product Identification',
  },
  {
    s_no: 13,
    heading: 'Child Part Ultrasonic cleaning',
    check_point:
      'Tank Solution concentration, neutral water based cleaner SK-4053',
  },
  {
    s_no: 14,
    heading: 'Child Part Ultrasonic cleaning',
    check_point: 'Ph value for DM water',
  },
  {
    s_no: 15,
    heading: 'Child Part Ultrasonic cleaning',
    check_point: 'Temperature',
  },
  {
    s_no: 16,
    heading: 'Child Part Ultrasonic cleaning',
    check_point: 'Ultrasonic Cleaning Time',
  },
  {
    s_no: 17,
    heading: 'Child Part Ultrasonic cleaning',
    check_point: 'Ultrasonic Solution Change Over frequency',
  },
  {
    s_no: 18,
    heading: 'Child Part Ultrasonic cleaning',
    check_point: 'Filter Change Over frequency',
  },
  {
    s_no: 19,
    heading: 'Child Part Ultrasonic cleaning',
    check_point: 'Aluminum Foil test in tank',
  },
  {
    s_no: 20,
    heading: 'Child Part Ultrasonic cleaning',
    check_point: 'Ultrasonic Cleanning',
  },
  {
    s_no: 21,
    heading: 'Assembly',
    check_point: 'Part should be assemble as per work instruction',
  },
  {
    s_no: 22,
    heading: 'Assembly',
    check_point: 'Appearance',
  },
  {
    s_no: 23,
    heading: 'Orientation',
    check_point: 'Fixture Condition',
  },
  {
    s_no: 24,
    heading: 'Orientation',
    check_point: 'Orientation of Sub assy. and Hose',
  },
  {
    s_no: 25,
    heading: 'Orientation',
    check_point: 'Bending and Profile of part',
  },
  {
    s_no: 26,
    heading: 'Orientation',
    check_point: 'Orientation Marking',
  },
  {
    s_no: 27,
    heading: 'Orientation',
    check_point: 'Appearance',
  },
  {
    s_no: 28,
    heading: 'Clamping  Program No. Clamp Spec',
    check_point: 'Clamping Tool Condition',
  },
  {
    s_no: 29,
    heading: 'Clamping  Program No. Clamp Spec',
    check_point: 'Force',
  },
  {
    s_no: 30,
    heading: 'Clamping  Program No. Clamp Spec',
    check_point: 'Force',
  },
  {
    s_no: 31,
    heading: 'Clamping  Program No. Clamp Spec',
    check_point: 'Pressure',
  },
  {
    s_no: 32,
    heading: 'Clamping  Program No. Clamp Spec',
    check_point: 'Ear Gap',
  },
  {
    s_no: 33,
    heading: 'Profile Checking',
    check_point: 'Final Fixture Condition',
  },
  {
    s_no: 34,
    heading: 'Profile Checking',
    check_point: 'Fixture calibration confirmation',
  },
  {
    s_no: 35,
    heading: 'Profile Checking',
    check_point: 'Total Length',
  },
  {
    s_no: 36,
    heading: 'Profile Checking',
    check_point: 'Orientation',
  },
  {
    s_no: 37,
    heading: 'Profile Checking',
    check_point: 'Profile Check',
  },
  {
    s_no: 38,
    heading: 'Leakage Testing + Barcode Pasting',
    check_point: 'Lock Supply Conditions',
  },
  {
    s_no: 39,
    heading: 'Leakage Testing + Barcode Pasting',
    check_point: 'Leak Pressure ',
  },
  {
    s_no: 40,
    heading: 'Leakage Testing + Barcode Pasting',
    check_point: 'Testing Time',
  },
  {
    s_no: 41,
    heading: 'Leakage Testing + Barcode Pasting',
    check_point: 'Water in Tank for air under water Testing ',
  },
  {
    s_no: 42,
    heading: 'Leakage Testing + Barcode Pasting',
    check_point: 'Part Leakage',
  },
  {
    s_no: 43,
    heading: 'Leakage Testing + Barcode Pasting',
    check_point: 'Bar code Pasting',
  },
  {
    s_no: 44,
    heading: 'Ultrasonic cleaning of assembly',
    check_point:
      'Tank Solution concentration, neutral water based cleaner SK-4053 ',
    specification: 'Should be 3~5% of DM Water',
  },
  {
    s_no: 45,
    heading: 'Ultrasonic cleaning of assembly',
    check_point: 'Ph value for DM water',
    specification: 'Should be 7~9 Ph value',
  },
  {
    s_no: 46,
    heading: 'Ultrasonic cleaning of assembly',
    check_point: 'Temperature',
    specification: '55°~60°',
  },
  {
    s_no: 47,
    heading: 'Ultrasonic cleaning of assembly',
    check_point: 'Ultrasonic Cleaning Time',
    specification: '180 Second',
  },
  {
    s_no: 48,
    heading: 'Ultrasonic cleaning of assembly',
    check_point: 'Air Pressure',
    specification: '4~6 Bar',
  },
  {
    s_no: 49,
    heading: 'Ultrasonic cleaning of assembly',
    check_point: 'Ultrasonic Solution Change Over frequency',
    specification: 'Maximum 15000 pcs.',
  },
  {
    s_no: 50,
    heading: 'Ultrasonic cleaning of assembly',
    check_point: 'Filter Change Over frequency',
    specification: 'As per preventive maintenance plan',
  },
  {
    s_no: 51,
    heading: 'Ultrasonic cleaning of assembly',
    check_point: 'Aluminum Foil test in tank',
    specification: 'Every Day',
  },
  {
    s_no: 52,
    heading: 'Dryer',
    check_point: 'Temperature',
    specification: '80° Max.',
  },
  {
    s_no: 53,
    heading: 'Dryer',
    check_point: 'Dryer Time',
    specification: '4 Minute.',
  },
  {
    s_no: 54,
    heading: 'Dryer',
    check_point: 'Part Dryer',
    specification: 'Part Should be completely dry.',
  },
  {
    s_no: 55,
    heading: 'Other',
    check_point: "Use of PPE's ",
  },
  {
    s_no: 56,
    heading: 'Other',
    check_point: "Use of PPE's ",
  },
  {
    s_no: 57,
    heading: 'Other',
    check_point: "Use of PPE's ",
  },
];

export const checklist2 = [
  
  {
    s_no: 1,
    heading: 'NRV Insertion & Testing',
    check_point: 'NRV Condition',
    specification: 'No leakage and damage',
  },
  {
    s_no: 2,
    heading: 'Profile Checking',
    check_point: 'Final Fixture Condition',
    specification: 'Should be done hygeine test before using',
  },
  {
    s_no: 3,
    heading: 'Profile Checking',
    check_point: 'Fixture calibration confirmation',
    specification: 'Calibration sticker',
  },
  {
    s_no: 4,
    heading: 'Profile Checking',
    check_point: 'Total Length',
    specification: 'As per drawing',
  },
  {
    s_no: 5,
    heading: 'Profile Checking',
    check_point: 'Orientation',
    specification: 'As per drawing',
  },
  {
    s_no: 6,
    heading: 'Profile Checking',
    check_point: 'Profile Check',
    specification: 'As per drawing',
  },
  {
    s_no: 7,
    heading: 'Visual Inspection',
    check_point: 'Visual Inspection',
    specification: 'Free from dents,burr,crack,damage and scratch etc.',
  },
  {
    s_no: 8,
    heading: 'O-Ring/ Clamp , plastic cap Insertion',
    check_point: 'Plastic Cap Presence',
    specification: 'Should be available',
  },
  {
    s_no: 9,
    heading: 'Heat Shield insertion',
    check_point: 'Heat Shield insertion',
    specification: 'Should be available',
  },
  {
    s_no: 10,
    heading: 'Primary Packaging',
    check_point: 'VCI Bag size',
    specification: 'As per VCI bag packing standard',
  },
  {
    s_no: 11,
    heading: 'Primary Packaging',
    check_point: 'Bar code scanning',
    specification: 'Should be available ',
  },
  {
    s_no: 12,
    heading: 'Primary Packaging',
    check_point: 'Part Quantity in VCI bag',
    specification: 'As per packing standard',
  },
  {
    s_no: 13,
    heading: 'Final Packing',
    check_point: 'Box Size'
  },
  {
    s_no: 14,
    heading: 'Final Packing',
    check_point: 'Part Quantity'
  },
  {
    s_no: 15,
    heading: 'Final Packing',
    check_point: 'Corrugated box Packing'
  },
  {
    s_no: 16,
    heading: 'Dock Audit',
    check_point: 'As per PDI Check Sheet'
  },
  {
    s_no: 17,
    heading: 'FG Storage',
    check_point: 'Proper Stacking & Location'
  },
  {
    s_no: 18,
    heading: 'FG Storage',
    check_point: 'FIFO'
  },
  {
    s_no: 19,
    heading: 'FG Storage',
    check_point: 'Box Identification'
  },
  {
    s_no: 20,
    heading: 'FG Storage',
    check_point: 'Pallet'
  },
  {
    s_no: 21,
    heading: 'Palleting',
    check_point: 'Product Identification'
  },
  {
    s_no: 22,
    heading: 'Other',
    check_point: "Use of PPE's",
    specification: '',
  },
  {
    s_no: 23,
    heading: 'Other',
    check_point: "Use of PPE's",
    specification: '',
  },
  {
    s_no: 24,
    heading: 'Other',
    check_point: "Use of PPE's",
    specification: '',
  },
];

export const checklist3 = [
  {
    s_no: 1,
    heading: 'End Forming',
    check_point: 'Flaring OD'
  },
  {
    s_no: 2,
    heading: 'End Forming',
    check_point: 'Flaring Mouth OD'
  },  
  {
    s_no: 3,
    heading: 'End Forming',
    check_point: 'Bead Length'
  },  
  {
    s_no: 4,
    heading: 'End Forming',
    check_point: 'Pipe Ovality'
  },  
  {
    s_no: 5,
    heading: 'End Forming',
    check_point: 'Concentricity'
  },  
  {
    s_no: 6,
    heading: 'End Forming',
    check_point: 'Radius 1'
  },  
  {
    s_no: 7,
    heading: 'End Forming',
    check_point: 'Radius 2'
  },  
  {
    s_no: 8,
    heading: 'End Forming',
    check_point: 'Visual defect'
  },  
  {
    s_no: 9,
    heading: 'End Forming',
    check_point: 'Air pressure'
  },  
  {
    s_no: 10,
    heading: 'Bending(CNC)',
    check_point: 'Tube Bend C0-ordinates'
  }, 
  {
    s_no: 11,
    heading: 'Bending(CNC)',
    check_point: 'Profile'
  }, 
  {
    s_no: 12,
    heading: 'Bending(CNC)',
    check_point: 'Radius 1'
  }, 
  {
    s_no: 13,
    heading: 'Bending(CNC)',
    check_point: 'Bend Collapse'
  }, 
  {
    s_no: 14,
    heading: 'Bending(CNC)',
    check_point: 'Visual defects'
  }, 
  {
    s_no: 15,
    heading: 'Tack & Welding (Tig/Spot)',
    check_point: 'Visual defects(Tack.)'
  }, 
  {
    s_no: 16,
    heading: 'Tack & Welding (Tig/Spot)',
    check_point: 'Current(Tack.)'
  }, 
  {
    s_no: 17,
    heading: 'Tack & Welding (Tig/Spot)',
    check_point: 'Gas Flow(Tack.)'
  }, 
  {
    s_no: 18,
    heading: 'Tack & Welding (Tig/Spot)',
    check_point: 'Part Position(Tack.)'
  }, 
  {
    s_no: 19,
    heading: 'Tack & Welding (Tig/Spot)',
    check_point: 'Visual defects(Weld.)'
  }, 
  {
    s_no: 20,
    heading: 'Tack & Welding (Tig/Spot)',
    check_point: 'Current(Weld.)'
  }, 
  {
    s_no: 21,
    heading: 'Tack & Welding (Tig/Spot)',
    check_point: 'Gas Flow(Weld.)'
  }, 
  {
    s_no: 22,
    heading: 'Tack & Welding (Tig/Spot)',
    check_point: 'Part Position(Weld.)'
  }, 
  {
    s_no: 23,
    heading: 'Brazing (Induction)',
    check_point: 'Visual defects'
  },
  {
    s_no: 24,
    heading: 'Brazing (Induction)',
    check_point: 'Filler Material'
  },
  {
    s_no: 25,
    heading: 'Brazing (Induction)',
    check_point: 'Component position'
  },
  {
    s_no: 26,
    heading: 'Brazing (Induction)',
    check_point: 'Penetration'
  },
  {
    s_no: 27,
    heading: 'Brazing (Induction)',
    check_point: 'Power'
  },
  {
    s_no: 28,
    heading: 'Brazing (Induction)',
    check_point: 'Time'
  },
  {
    s_no: 29,
    heading: 'Brazing (Induction)',
    check_point: 'Ring 01Nos'
  },  
  {
    s_no: 30,
    heading: 'Brazing (Furnace)',
    check_point: 'Visual defects'
  },
  {
    s_no: 31,
    heading: 'Brazing (Furnace)',
    check_point: 'Filler Material'
  },
  {
    s_no: 32,
    heading: 'Brazing (Furnace)',
    check_point: 'Component position'
  },
  {
    s_no: 33,
    heading: 'Brazing (Furnace)',
    check_point: 'Penetration'
  },
  {
    s_no: 34,
    heading: 'Brazing (Furnace)',
    check_point: 'Power'
  },
  {
    s_no: 35,
    heading: 'Brazing (Furnace)',
    check_point: 'Time'
  },
  {
    s_no: 36,
    heading: 'Brazing (Furnace)',
    check_point: 'Ring 01Nos'
  },   
  {
    s_no: 37,
    heading: 'Blockage Testing',
    check_point: 'Machine validation for OK and NOT OK sample'
  },  
  
  {
    s_no: 38,
    heading: 'Blockage Testing',
    check_point: 'Selection of jig for Blockage Testing'
  },  
  {
    s_no: 39,
    heading: 'Blockage Testing',
    check_point: 'Blockage Testing of parts and Identification of OK and NOT OK parts'
  },   
  {
    s_no: 40,
    heading: 'Fitment of Sub Assembly',
    check_point: 'Part fiment as per fitment fixrure'
  },  
  {
    s_no: 41,
    heading: 'Fitment of Sub Assembly',
    check_point: 'Idetification for OK parts and NOT OK parts'
  },   
  {
    s_no: 42,
    heading: 'Laser Marking',
    check_point: 'Laser markiing visiblilty'
  },
  {
    s_no: 43,
    heading: 'Laser Marking',
    check_point: 'Part programme as per std.'
  },
  {
    s_no: 44,
    heading: 'Flushing',
    check_point: 'Pressure 4-5 Bar'
  },
  {
    s_no: 45,
    heading: 'Flushing',
    check_point: 'Time 30 Sec'
  },
  {
    s_no: 46,
    heading: 'Flushing',
    check_point: 'Oil DW823 Level maintaing'
  },
  {
    s_no: 47,
    heading: 'Leica / Pall Testing',
    check_point: 'Fittining must be clean and no leakage'
  },
  {
    s_no: 48,
    heading: 'Leica / Pall Testing',
    check_point: 'Haku 1025-921 checmical using'
  },
  {
    s_no: 49,
    heading: 'Leica / Pall Testing',
    check_point: 'Pressure 6 Bar'
  },
  {
    s_no: 50,
    heading: 'Leica / Pall Testing',
    check_point: 'Millipore 6 mg'
  },
  {
    s_no: 51,
    heading: 'Leica / Pall Testing',
    check_point: 'Cleanliness leica Max 1000 Micron'
  },
  {
    s_no: 52,
    heading: 'Other',
    check_point: "Use of PPE's"
  },
  {
    s_no: 53,
    heading: 'Other',
    check_point: "Use of PPE's"
  },
  {
    s_no: 54,
    heading: 'Other',
    check_point: "Use of PPE's"
  }
]
