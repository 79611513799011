import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
interface IProps {
  moduleData: any;
  setModuleData: React.Dispatch<React.SetStateAction<any>>;
  isView: boolean;
  phase?: any;
  title?: string;
  project: string;
}

const TController = ({
  moduleData,
  setModuleData,
  isView,
  phase,
  title,
  project,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            project,
            title,
          }}
        />
        <TBody {...{ moduleData, setModuleData, phase, isView }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
