import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import GridDatePicker from '../../components/common/inputs/GridDatePicker';
import GridSelect from '../../components/common/inputs/GridSelect';
import GridInputs from '../../components/common/inputs/GridInputs';
import { StyledNotesField } from '../../components/common/FormControlMui';
import DescriptionTextfield from '../../components/common/DescriptionNotes';
import { moduleConstant } from './helpers/constant';
import { Loader } from '../../components/common/Loader';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    date_of_audit: new Date(),
    audit_name: '',
    audit_observation: '',
    action_taken: '',
    action_resp_person_name_and_dept: '',
    target_date_first: null,
    target_date_second: null,
    target_date_third: null,
    target_date_firts_status: '',
    target_date_second_status: '',
    target_date_third_status: '',
    remarks: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Action Plan' : 'Create Action Plan'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`${moduleConstant.api}/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSubmit = async () => {
    if (!data.audit_name) {
      alert('Please select audit name');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);

    if (id) {
      await axios
        .put(`${moduleConstant.api}/${id}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      await axios
        .post(`${moduleConstant.api}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <GridDatePicker
        label_name="Date of Audit"
        html_for={'date_of_audit'}
        value={data?.date_of_audit || null}
        onChange={(date) => {
          setData((prev: any) => ({ ...prev, date_of_audit: date }));
        }}
      />
      <GridSelect
        name="audit_name"
        label_name="Audit Name"
        html_for={'audit_name'}
        value={data?.audit_name || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="process">Process</MenuItem>
        <MenuItem value="product">Product</MenuItem>
      </GridSelect>

      <StyledNotesField>
        <DescriptionTextfield
          name="audit_observation"
          onChange={handleCustomerData}
          value={data?.audit_observation || ''}
          label={'Audit Observation :'}
        />
      </StyledNotesField>

      <StyledNotesField>
        <DescriptionTextfield
          name="action_taken"
          onChange={handleCustomerData}
          value={data?.action_taken || ''}
          label={'Action Taken :'}
        />
      </StyledNotesField>

      <GridInputs
        id={'action_resp_person_name_and_dept'}
        name={'action_resp_person_name_and_dept'}
        html_for={'action_resp_person_name_and_dept'}
        label_name={'Action Resp. Person Name & Dept.'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.action_resp_person_name_and_dept || ''}
        onChange={handleCustomerData}
      />
      
      <GridDatePicker
        label_name="Target Date (1st)"
        html_for={'target_date_first'}
        value={data?.target_date_first || null}
        onChange={(date) => {
          setData((prev: any) => ({ ...prev, target_date_first: date }));
        }}
      />
      <GridSelect
        name="target_date_firts_status"
        label_name="Status (1st)"
        html_for={'target_date_firts_status'}
        value={data?.target_date_firts_status || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Open">Open</MenuItem>
        <MenuItem value="Closed">Closed</MenuItem>
        <MenuItem value="In Progress">In Progress</MenuItem>
      </GridSelect>

      <GridDatePicker
        label_name="Target Date (2nd)"
        html_for={'target_date_second'}
        value={data?.target_date_second || null}
        onChange={(date) => {
          setData((prev: any) => ({ ...prev, target_date_second: date }));
        }}
      />
      <GridSelect
        name="target_date_second_status"
        label_name="Status (2nd)"
        html_for={'target_date_second_status'}
        value={data?.target_date_second_status || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Open">Open</MenuItem>
        <MenuItem value="Closed">Closed</MenuItem>
        <MenuItem value="In Progress">In Progress</MenuItem>
      </GridSelect>

      <GridDatePicker
        label_name="Target Date (3rd)"
        html_for={'target_date_third'}
        value={data?.target_date_third || null}
        onChange={(date) => {
          setData((prev: any) => ({ ...prev, target_date_third: date }));
        }}
      />
      <GridSelect
        name="target_date_third_status"
        label_name="Status (3rd)"
        html_for={'target_date_third_status'}
        value={data?.target_date_third_status || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Open">Open</MenuItem>
        <MenuItem value="Closed">Closed</MenuItem>
        <MenuItem value="In Progress">In Progress</MenuItem>
      </GridSelect>

      <StyledNotesField>
        <DescriptionTextfield
          name="remarks"
          onChange={handleCustomerData}
          value={data?.remarks || ''}
          label={'Remarks :'}
        />
      </StyledNotesField>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSubmit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;