import { createConstant } from '../../constant';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ preparedBy, approvedBy }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <th colSpan={16 + createConstant.extracolSpan}>
          Note : Product audit will be done just after Customer Complaints
          Closer.
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Prepared By: {preparedBy}
        </th>
        <th colSpan={3} align="left">
          Approved By: {approvedBy}
        </th>
        <th colSpan={1} align="left">
          LEGENDS
        </th>
        <th style={{ backgroundColor: '#ffd43b' }}>P</th>
        <th>Plan</th>
        <th style={{ backgroundColor: '#37b24d' }}>A</th>
        <th>Actual</th>
        <th colSpan={5}></th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
