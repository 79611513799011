import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { apiConstant } from '../constant';
import { TableCompHead } from '../../../components/ui';
import { TableInput } from '../../../components/common';
import DatePickerMui from '../../../components/common/DatePickerMui';
import {TextAreaInput} from '../../../components/common';
import { Table } from '../../../components/ui/renderTable/styled';
import moment from 'moment';

const Create = () => {
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const customer = useQuery('customer');
  const documentId = useQuery('documentId');
  const listItemObjectId = useQuery('listItemObjectId');
  const month = useQuery('month');

  const [moduleData, setModuleData] = useState<any>({
    observations: Array(10).fill({
      date: null,
      observation: '',
      remarks: ''
    })
  });

  useEffect(() => {
    if (moduleId) {
      axios
        .get(`/api/${apiConstant.observationListData}/${moduleId}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [moduleId]);

  const onSubmit = async () => {
    let response: any;
    if (moduleId) {
      response = await axios
        .put(`/api/${apiConstant.observationListData}/${moduleId}`, moduleData)
        .then((res) => {
          alert('updated successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    } else {
      response = await axios
        .post(
          `/api/${apiConstant.observationListData}?documentId=${documentId}&listItemObjectId=${listItemObjectId}&month=${month}`,
          moduleData
        )
        .then((res) => {
          alert('created successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="Observations">
          {!isView && (
            <SubmitButton label="Submit" onClick={() => onSubmit()} />
          )}
        </ChildHeader>
      </Box>

      <Table >
        <thead>
          <TableCompHead
            colSpan={4}
            rowSpan={3}
            labels={[]}
            values={[]}
            heading="Product Audit Observations"
          />
          <tr>
            <th>S. NO.</th>
            <th>DATE</th>
            <th>OBSERVATION</th>
            <th>REMARKS</th>
          </tr>
        </thead>
        <tbody>
          {moduleData.observations?.map((observation: any, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td style={{ width: '200px', textAlign: 'center' }}>
                {isView ? (
                  moment(observation.date).format('DD-MM-YYYY')
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(observation.date)}
                    onChange={(date) => {
                      const newObservations = [...moduleData.observations];
                      newObservations[index] = {
                        ...newObservations[index],
                        date: date
                      };
                      setModuleData({
                        ...moduleData,
                        observations: newObservations
                      });
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '300px', textAlign: 'center' }}>
                <TextAreaInput
                  isView={isView}
                  name="observation"
                  value={observation.observation}
                  onChange={(e) => {
                    const newObservations = [...moduleData.observations];
                    newObservations[index] = {
                      ...newObservations[index],
                      observation: e.target.value
                    };
                    setModuleData({
                      ...moduleData,
                      observations: newObservations
                    });
                  }}
                />
              </td>
              <td style={{ minWidth: '200px', textAlign: 'center' }}>
                <TextAreaInput
                  isView={isView}
                  name="remarks"
                  value={observation.remarks}
                  onChange={(e) => {
                    const newObservations = [...moduleData.observations];
                    newObservations[index] = {
                      ...newObservations[index],
                      remarks: e.target.value
                    };
                    setModuleData({
                      ...moduleData,
                      observations: newObservations
                    });
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Box>
  );
};

export default Create;