import { Box } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import useConfirm from '../../../components/common/useConfirm';
import { processObservationApiConstants, processObservationConstants } from '../constant';
import StatusTable from './components/StatusTable';
import { Loader } from '../../../components/common/Loader';

const Sheet = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [sheetData, setSheetData] = useState<any>({});
  const [isSheetLocked, setIsSheetLocked] = useState(false);

  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to save these changes?'
  );

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axios
        .get(`/api/${processObservationApiConstants.getById}/${id}`)
        .then((res) => {
          setSheetData(res.data);
          // Check if any month is closed to determine if sheet is locked
          setIsSheetLocked(
            Object.values(res.data.months).some(
              (month: any) => month.closingStatus
            )
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [id]);

  const onSubmit = async () => {
    const ans = await confirmSave();
    if (!ans) return;

    axios
      .put(`/api/${processObservationApiConstants.update}/${id}`, sheetData)
      .then(() => alert('Updated successfully'))
      .catch((err) => console.log(err));
  };

  return (
    <Box>
      <Loader loader={isLoading} />
      <ChildHeader text={processObservationConstants.sheetChildHeader}>
        <SubmitButton label="Save" onClick={onSubmit} />
      </ChildHeader>
      <StatusTable
        sheetData={sheetData}
        setSheetData={setSheetData}
        isLocked={isSheetLocked}
      />
      <DialogSave isSubmitContent={false} />
    </Box>
  );
};

export default Sheet;