import lodash from 'lodash';
const months = Array.from({ length: 16 }, () => ({}));
export const tabData = [
  { phase: 'phase_1', title: 'PHASE-1 (PLAN AND DEFINE PROGRAM)' },
  {
    phase: 'phase_2',
    title: 'PHASE-2 (PRODUCT DESIGN AND DEVELOPMENT)',
  },
  { phase: 'phase_3', title: 'PHASE-3 (PROCESS DESIGN AND DEVELOPMENT)' },
  {
    phase: 'phase_4',
    title: 'PHASE-4 (PRODUCT AND PROCESS VALIDATION)',
  },
  {
    phase: 'phase_5',
    title: 'PHASE-5 (FEEDBACK, ASSESSMENT AND CORRECTIVE ACTION)',
  },
];

export const npdTimePlanConstant = {
  phase_1: [
    {
      s_no: 1,
      activity: 'RECEIPT OF RFQ. ALONG WITH SPECS. / DRAWINGS',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 2,
      activity: 'CIR/STANDARD REQUIREMENT',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 3,
      activity: 'CROSS FUNCTIONAL TEAM FORMATION',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 4,
      activity: 'TEAM FEASIBILITY',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 5,
      activity: 'RISK ASSESSMENT',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 6,
      activity: 'DRAWING/PART TECHNICAL FEASIBILITY REVIEW WITH CUSTOMER',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 7,
      activity: 'DRAWING RECEIVED FROM CUSTOMER AS PER FEASIBILITY',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 8,
      activity: 'CUSTOMER NOMINATION',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 9,
      activity: 'FEASIBILITY SIGNED OFF WITH CUSTOMER',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 10,
      activity: 'RELEASE OF PURCHASE ODER FROM CUSTOMER',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 11,
      activity: 'PRELIMINARY BILL OF MATERIAL',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 12,
      activity: 'RAMP UP ACTIVITY PLAN',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 13,
      activity: 'REVIEW (MoM)',
      months: lodash.cloneDeep(months),
    },
  ],
  phase_2: [
    {
      s_no: 1,
      activity: 'DESIGN REVIEW / ANALYSIS',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 2,
      activity: 'LISTING OF SPECIAL PRODUCT & PROCESS CHARACTERISTICS',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 3,
      activity: 'CHILD PART DRAWING RELEASE AT IMPERIAL',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 4,
      activity: 'FTG PLANNING',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 5,
      activity: 'CHILD PART DRAWING REVIEW AT IMPERIAL',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 6,
      activity: 'SUPPLIER IDENTIFICATION',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 7,
      activity: 'RFQ TO SUPPLIER FOR CHILD PART DEVELOPMENT',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 8,
      activity: 'FEASIBILITY REVIEW WITH SUPPLIER',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 9,
      activity: 'PO RELEASE TO SUPPLIER',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 10,
      activity: 'DESIGN RELEASE FOR TOOL AND GAUGES',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 11,
      activity:
        'QUOTE BY SUPPLIER PO RELEASE BY IMPERIAL FOR TOOLING AND GAUGES',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 12,
      activity: 'PROTO CHILD PART RECEIPT AND INSPECTION',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 13,
      activity: 'INSTALLATION PLAN (IF APPLICABLE)',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 14,
      activity: 'TOOL TRIAL PLAN / REVIEW',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 15,
      activity: 'CHILD PART TESTING PLAN',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 16,
      activity: 'PRELIMINARY PROCESS FLOW CHART',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 17,
      activity: 'PROTOTYPE PROCESS FMEA',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 18,
      activity: 'PROTOTYPE BUILD CONTROL PLAN',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 19,
      activity: 'PACKING DESIGN & PLAN',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 20,
      activity: 'SET QYALITY TARGETS',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 21,
      activity: 'SUPPLIER / SUB SUPPLIER APQP STATUS SHEET',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 22,
      activity: 'PROTOTYPE BUILDS',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 23,
      activity: 'IMPERIAL VALIDATION / TEST.',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 24,
      activity: 'PROTO SAMPLE SUBMISSION TO CUSTOMER',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 25,
      activity: 'REVIEW FEEDBACK OF PROTO SAMPLES',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 26,
      activity: 'REVIEW (MoM)',
      months: lodash.cloneDeep(months),
    },
  ],
  phase_3: [
    {
      s_no: 1,
      activity: 'OFF TOOL SAMPLE PO TO SUPPLIER',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 2,
      activity: 'TOOL RECEIPT AND INSPECTION',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 3,
      activity: 'TOOL TRIAL',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 4,
      activity: 'OFF TOOL CHILD PART / BOP / COMPONENTS MANUFACTURING',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 5,
      activity:
        'FIRST OFF TOOL (FOT) CHILD PART / BOP/ COMPONENTS RECEIPT AND INSPECTION',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 6,
      activity: 'OFF TOOL PROCESS FMEA',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 7,
      activity: 'OFF TOOL CONTROL PLAN',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 8,
      activity: 'MSA PLAN',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 9,
      activity: 'PRELIMINARY PROCESS CAPABILITY STUDY PLAN',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 10,
      activity: 'FLOOR LAYOUT PLAN (ASSY./ PLANT LAYOUT)',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 11,
      activity: 'FACILITY AND EQUIPMENTS TRIAL',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 12,
      activity: 'OFF TOOL SAMPLE MANUFACTURING',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 13,
      activity: 'OFF TOOL SAMPLE IMPERIAL VALIDATION & REPORT',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 14,
      activity: 'OFF TOOL SAMPLE INSPECTION & REPORT',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 15,
      activity: 'OFF TOOL SAMPLE INTERNAL APPROVAL',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 16,
      activity: 'FIRST OFF TOOL SAMPLE SUBMISSION TO CUSTOMER',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 17,
      activity: 'CUSTOMER FIRST OFF TOOL SAMPLE APPROVAL',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 18,
      activity: 'REVIEW FEEDBACK OF OFF TOOL SAMPLES',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 19,
      activity: 'REVIEW (MoM)',
      months: lodash.cloneDeep(months),
    },
  ],
  phase_4: [
    {
      s_no: 1,
      activity: 'PRODUCTION PROCESS FLOW CHART',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 2,
      activity: 'PRODUCTION PROCESS FMEA',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 3,
      activity: 'PRODUCTION CONTROL PLAN',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 4,
      activity: 'SUPPLIER / SUB-SUPPLIER PPAP',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 5,
      activity: 'INITIAL SAMPLE PRODUCTION TRIAL RUN',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 6,
      activity: 'PRODUCTION VALIDATION TESTING AND REPORTS',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 7,
      activity: 'MEASUREMENT SYSTEM ANALYSIS',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 8,
      activity: 'SPC AND QUALITY TARGETS REVIEW',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 9,
      activity: 'PREPARE WI or JES, PIR FORMATS, CHECK SHEETS',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 10,
      activity: 'PACKAGING AND LOGISTICS FINALIZATION AND TRIAL',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 11,
      activity: 'TRAINING NEED IDENTIFICATION',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 12,
      activity: 'RECRUITMENT AND TRAINING',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 13,
      activity: 'FINAL INSPECTION OF INITIAL SAMPLE',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 14,
      activity: 'INITIAL SAMPLE + PPAP SUBMISSION TO CUSTOMER',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 15,
      activity: 'INITIAL SAMPLE + PPAP APPROVAL',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 16,
      activity: 'REVIEW (MoM)',
      months: lodash.cloneDeep(months),
    },
  ],
  phase_5: [
    {
      s_no: 1,
      activity: 'FEED BACK ANALYSIS, CORRECTIVE ACTION',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 2,
      activity: 'PROJECT HANDING OVER',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 3,
      activity: 'SOP',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 4,
      activity: 'RAMP UP ACTIVITY',
      months: lodash.cloneDeep(months),
    },
    {
      s_no: 5,
      activity: 'REVIEW (MoM)',
      months: lodash.cloneDeep(months),
    },
  ],
};
