import React, { useState, useEffect } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
} from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';

import formatDate from '../../../components/common/formatDate';
import TableScroll from '../../../components/ui/renderTable/TableScroll';
import TextAreaInput from '../../../components/common/TextAreaInput';
import { tableHeaderObj } from './helpers/tableHeaderObj';
import ExcelUploader from '../../../components/common/excel/excelUpload/ExcelUploaderV1';
import { lessonLearnedUploadConfig } from './helpers/LessonLearnedListExcelConfig';

const colorOnStatus: any = {
  Open: '#ff6b6b',
  Closed: '#51cf66',
};

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [all, setAll] = useState(false);
  const page = useQuery('page') || 1;
  const [filterCustomer, setFilterCustomer] = useState<any>([]);
  const search = useQuery('search') || '';
  const date = useQuery('date');
  const customer = useQuery('customer');

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer._id,
          }));
          setFilterCustomer([...formattedData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/lessonLearnRoute/monthly/${date}?page=${page}&search=${search}&perPage=${perPage}&customer_id=${customer}&all=${all}`
      )
      .catch((err) => {
        console.log(err);
      });

    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteModule = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/lessonLearnRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    customer,
    all,
    date,
  ]);

  const handleUploadSuccess = (response: any) => {
    //alert('Documents uploaded successfully');
    setRender((prev) => !prev); // Refresh the list
  };

  const handleUploadError = (error: any) => {
    alert('Error uploading documents: ' + (error.message || 'Unknown error'));
  };

  return (
    <>
      <ChildHeader text="Lesson Learn">
        <AddButton
          onClick={() => {
            setAll((value) => !value);
          }}
          label={all ? 'Hide' : 'Save'}
        />
        <AddButton onClick={() => setIsModal(true)} label="New Lesson Learn" />
        <ExcelUploader
          config={lessonLearnedUploadConfig}
          apiEndpoint="/api/lessonLearnRoute/excelUpload"
          minimumColumnHeaderMatch={7} // Adjust this number based on how many columns should match
          onSuccess={handleUploadSuccess}
          onError={handleUploadError}
          skipForDataRows={4}
          footer={2}
        />
      </ChildHeader>
      <SearchFilterContainer filter={true} style={{ display: 'flex' }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
        <SearchBar label="Search By Part" sx={{ width: 900 }} />
        <FilterBy name="customer" label="Customer" options={filterCustomer} />
      </SearchFilterContainer>
      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: any, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.plant}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.reciept_date && formatDate(item.reciept_date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.category}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {' '}
                      {item.customer_id?.customer_name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.family}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {' '}
                      {item.part_id?.part_name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {' '}
                      {item.part_id?.part_number}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      <TextAreaInput
                        value={item.defect_details}
                        style={{ minWidth: '200px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.repeated}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.qty}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      <TextAreaInput
                        value={item.root_cause_for_occ}
                        style={{ minWidth: '200px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      <TextAreaInput
                        value={item.root_cause_for_non_detection}
                        style={{ minWidth: '200px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      <TextAreaInput
                        value={item.corrective_action_for_occ}
                        style={{ minWidth: '200px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      <TextAreaInput
                        value={item.corrective_action_for_non_detection}
                        style={{ minWidth: '200px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        border: '1px solid black',
                        background:
                          colorOnStatus?.[item?.action_implementation_status],
                      }}
                    >
                      {item?.action_implementation_status}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      <TextAreaInput
                        value={item.lesson_learnt}
                        style={{ minWidth: '200px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.qdcftsrm && item?.qdcftsrm[0]}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.applicability_and_action_am5_am6_applicabiliy}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.applicability_and_action_am5_am6_action}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {
                        item?.applicability_and_action_1085_kw_tsi_ind_applicabiliy
                      }
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.applicability_and_action_1085_kw_tsi_ind_action}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.applicability_and_action_am_370_applicabiliy}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.applicability_and_action_am_370_action}
                    </TableCell>

                    <TableCell sx={{ border: '1px solid black' }}>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setModuleId(item?._id || '');
                            setIsModal(true);
                          }}
                        />
                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteModule(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setModuleId(null);
          setIsModal(false);
        }}
        title="Lesson Learn"
      >
        <Create id={moduleId} setIsModal={setIsModal} />
      </ModalCustom>

      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
